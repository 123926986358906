import BaseService from "./BaseAPI";
import {AttributesProperty} from "./DictionaryAPI";


class AttributableService extends BaseService {
    constructor(id) {
        super(id);
        this.attributes = new AttributesProperty(this);
    }

    set_id(id) {
        super.set_id(id);
        this.attributes.set_id(this.get_id());
    }
}

export {
    AttributableService
}
