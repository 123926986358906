import {ContragentableService} from "app/API/ContragentBasedService";


class PPMailService extends ContragentableService {
    MODEL = 'ppmail';
}

export {
    PPMailService,
}
