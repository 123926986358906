import React, {useEffect, useState} from 'react'
import {Button, Form, Modal, Skeleton, Typography} from 'antd'
import {OfferService} from '../../../API/OfferAPI'
import OfferForm from '../Forms/OfferForm'
// import useProduct from '../ProductContext'
// import useUser from '../../../Providers/UserProvider'


export default function OfferModal({item, ...props}) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    // перенесён в форму
    const onFinish = (data) => {
        typeof props.onCancel === 'function' && props.onCancel();
    }

    useEffect(() => {
        form.resetFields();
        setLoading(true);
        if (item) {
            new OfferService(item?.id).get().then(res => {
                res.data.access_contragents = res.data?.access_contragents?.map(el => {
                    return el?.id
                });
                res.data.tags = res.data?.tags?.map(el => {
                    return el?.id
                });
                res.data.account = res.data?.account?.id
                form.setFieldsValue(res.data);
                setLoading(false);
            })
        } else if (props.product) {
            form.setFieldsValue({product: props.product.id});
            setLoading(false);
        }else{
            setLoading(false);
        }
    }, [item]);

    return (
        <Modal
            title={<Typography>{item?.id ? 'редактирование предложения' : 'создание предложения'}</Typography>}
            width={'80%'}
            footer={
                <Button type="primary" htmlType="submit"
                        form="offer_form">Сохранить</Button>
            }
            destroyOnClose
            {...props}
        >
            {loading ? new Array(3).fill(1).map(el => (<Skeleton />)) : 
                <OfferForm
                    id='offer_form'
                    item={item}
                    type={item?.type}
                    form={form}
                    onFinish={onFinish}
                />
            }
        </Modal>
    )
}
