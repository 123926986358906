import React from "react";
import { Form, Input, Switch} from "antd";


export default function EditForm({onFinish, form,...props}) {

  return (
    <Form layout='vertical' onFinish={onFinish} form={form} {...props}>
      <Form.Item label='имя пользователя' name='username'>
        <Input type='text'/>
      </Form.Item>
      <Form.Item label='пароль' name='password'>
        <Input.Password type='password'/>
      </Form.Item>

      <Form.Item label='Имя' name='first_name'>
        <Input type='text' />
      </Form.Item>
      <Form.Item label='Фамилия' name='last_name'>
        <Input type='text' />
      </Form.Item>
      <Form.Item label='Электронная почта' name='email'>
        <Input type='text' />
      </Form.Item>
      <Form.Item label='Активен' name='is_active' initialValue={true}>
        <Switch checked />
      </Form.Item>
    </Form>
  )

}
