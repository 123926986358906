import React from "react";
import {Card, Layout} from 'antd';
import CtgSelectForm from "./Forms/CtgSelectForm"
import './style.css';


export default function CtgSelectModal(){
    return <Layout style={{width: '100%', background: '#fed74d', height: '100vh', display: 'flex', alignItems: 'center'}}>
        {/* Вынести svg в отдельный файл */}
        {/*<Banner/>*/}
        {/*<UserProvider>*/}
            <Card style={{borderRadius: '10px', width: '350px', textAlign: 'center', margin: '50px'}}>
                <img src='/img/logo.png' alt='logo'
                   style={{marginBottom: '1em', width: '100px'}}/>
                <CtgSelectForm/>
            </Card>
        {/*</UserProvider>*/}
    </Layout>
}
