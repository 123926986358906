import React from "react";
import {Button, Modal} from "antd";
import DebitForm from "../Forms/DebitForm";


export default function DebitModal({onSuccess, ...props}) {

    return (
        <Modal
            destroyOnClose
            {...props}
            title="Списать"
            footer={
                <Button type="primary" htmlType="submit" form="debit_form">
                    Списать
                </Button>
            }
        >
            <DebitForm
                id="debit_form"
                onFinish={onSuccess}
            />
        </Modal>
    );
}
