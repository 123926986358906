import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Descriptions, Form, InputNumber, message, Radio, Typography, Input,
     Row, Col, Table} from 'antd'
import {SelectOfferModal, SelectPackageModal} from '../Modals/SelectOfferModal'
import {OfferService} from 'src/API/OfferAPI';
import {PackageService} from 'src/API/PackageAPI';
import { AtolSubscribeService, AtolSubscribeGroupService } from '../../../API/AtolAPI';
import Money from '../../Accounts/utils/Money';
import {SubscribeService, SubscribeServiceGroup} from 'src/API/ServiceAPI';
import useUser from "src/Providers/UserProvider";

import moment from 'moment';
import FormItem from 'antd/lib/form/FormItem';


const { Text } = Typography;

export function ChangePriceInfo({ changeData }) {
    const columns = [
        {
            title: 'Текущая подписка',
            dataIndex: 'remain_plan_name',
            key: 'remain_plan_name',
        },
        {
            title: 'Текущая подписка (неотработанные дни)',
            dataIndex: 'remain_plan_count_days',
            key: 'remain_plan_count_days',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Текущая подписка (сумма)',
            dataIndex: 'remain_plan_sum',
            key: 'remain_plan_sum',
            render: (text) => <Money sum={text} />,
        },
        {
            title: 'Следующая подписка',
            dataIndex: 'new_offer_plan_name',
            key: 'new_offer_plan_name',
        },
        {
            title: 'Следующая подписка (дни)',
            dataIndex: 'new_offer_plan_count_days',
            key: 'new_offer_plan_count_days',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Следующая подписка (сумма)',
            dataIndex: 'new_offer_plan_sum',
            key: 'new_offer_plan_sum',
            render: (text) => <Money sum={text} />,
        },
        {
            title: 'Стоимость смены',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (text, record) => (
                <Text>
                    <Money sum={record.new_offer_plan_sum}/> - <Money sum={record.remain_plan_sum}/> = <Money sum={text}/>
                </Text>
            )
        }
    ];

    const dataSource = changeData && changeData.items ? changeData.items.map((item, index) => ({
        ...item,
        key: index
    })) : [];

    return (
        <div>
            <Table
                size='small'
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            {changeData && changeData.change_price &&
                <div style={{ marginTop: '16px' }}>
                    <Text strong>Итоговая стоимость: <Money sum={changeData.change_price} /></Text>
                </div>
            }
            <br />
        </div>
    );
}


export default function CrossgradeServiceChangeForm({item, service_item, isAtolSpecial, ...props}) {
    const [visibleSelectOfferModal, setVisibleSelectOfferModal] = useState(false);
    const [detailSubscribe, setDetailSubscribe] = useState(service_item?.id);
    const [offerDetail, setOfferDetail] = useState(null);
    const {user, time, time_machine} = useUser();
    const [form] = Form.useForm();
    const [totalCost, setTotalCost] = useState(0)
    const [changeData, setChangeData] = useState(null)

    const onFinishForm = (data) => {
        data['offer'] = offerDetail?.id;
        if (data?.date_start) {
            data['date_start'] = moment(data.date_start);
        }
        if (data?.prolong_date) {
            data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        }

        if (data && !isAtolSpecial) { //TODO: использование props.<property> нежелательно. Обозначить явный ключ в объекте
            new SubscribeService(service_item?.id).createCrossgrade(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
                message.success('Создан заказ на смену.')
            })
        }

        if (data && isAtolSpecial) {
            new AtolSubscribeService(service_item?.id).change_after_revenue_correction(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
            })
        }
    }

    useEffect(() => {
        if (service_item) {
            new SubscribeService(service_item?.id).getPlan()
            new SubscribeService(service_item?.id).getDetalization()
            new SubscribeService(service_item?.id).get().then(res => {
                setDetailSubscribe(res.data);
            })
        }
    }, [service_item])

    useEffect(() => {
        if (detailSubscribe?.offer) {
            new OfferService(detailSubscribe?.offer).get().then(res => {
                setOfferDetail(res.data);
            })
        }
    }, [detailSubscribe])

    useEffect(() => {
        if (offerDetail?.id) {
            new SubscribeService().calcProlongDate({
                current_subscribe_id: item.id,
                change_offer_id: offerDetail.id,
            }).then(res => {
                form.setFieldsValue({
                    prolong_date: moment(res.data.new_settling_date)
                })
                setTotalCost(res.data.total_cost)
                setChangeData(res.data.additional_data)
            })
        }

        if (detailSubscribe) {
            form.setFieldsValue({
                count: detailSubscribe.subscribe_count
            })
        }

        
    }, [offerDetail, detailSubscribe, form])

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues.count || changedValues.is_crossgrade !== undefined) {
            new SubscribeService().calcProlongDate({
                current_subscribe_id: item.id,
                change_offer_id: offerDetail.id,
                count: allValues.count,
                is_immediately: allValues.is_crossgrade
            }).then(res => {
                setTotalCost(res.data.total_cost)
                setChangeData(res.data.additional_data)
            })
        }
    };

    return (
        <Form layout='vertical' 
            {...props} 
            form={form} 
            onFinish={onFinishForm}
            onValuesChange={onValuesChange} 
        >
            {offerDetail &&
            <Form.Item>
                <Descriptions column={1} title='Тариф' bordered size='small' extra={<Typography>
                    <Button type='link' onClick={() => setVisibleSelectOfferModal(true)}>Сменить тариф</Button>
                </Typography>}>
                    <Descriptions.Item label='Наименование предложения'>{offerDetail?.name}</Descriptions.Item>
                    <Descriptions.Item label='Цена за единицу'><Money sum={offerDetail?.price}
                                                           currency={offerDetail?.currency}/></Descriptions.Item>
                    <Descriptions.Item label='НДС'><Money.NDS nds={offerDetail?.taxes}/></Descriptions.Item>
                    <Descriptions.Item
                        label='Единица измерения'>{offerDetail?.product?.measure_name || 'не задан'}</Descriptions.Item>
                </Descriptions>
            </Form.Item>
            }

            {isAtolSpecial &&
                <Form.Item label='Дата продления' name='prolong_date' initialValue={moment(service_item?.settling_day)}>
                    <DatePicker defaultPickerValue={time} showToday={false} dateRender={current => {
                        let css_prop = {}
                        if (current == time) {
                            css_prop.border = '1px solid blue';
                        }
                        return <div className="ant-picker-cell-inner" style={css_prop}>
                            {current.date()}
                        </div>
                    }} style={{width: '100%'}} disabledDate={(current) => {
                        return time_machine ? current < time : current && current < moment().endOf('day');
                    }}/>
                </Form.Item>
            }
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label='Количество:' name='count' id='count'>
                        <InputNumber size='small' min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label='Полная стоимость:'>
                        <p><Money sum={totalCost} /></p>
                    </Form.Item>
                </Col>
            </Row>

            {form.getFieldValue('is_crossgrade') && <ChangePriceInfo changeData={changeData} />}

            <SelectOfferModal
                visible={visibleSelectOfferModal}
                onCancel={() => setVisibleSelectOfferModal(false)}
                onSelectOffer={(record) => {
                    setOfferDetail(record);
                    setVisibleSelectOfferModal(false);
                }}
            />

            <Form.Item label={isAtolSpecial ? 'Пересчитать цену и создать заказ' : 'Тип смены'}
                name='is_crossgrade' initialValue={false}>
                <Radio.Group>
                    <Radio disabled={true} value={true}>Применить с текущей даты до конца периода</Radio>
                    <Radio value={false}>Применить со следующего периода</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}

export function CrossgradeServiceGroupChangeForm({item, service_item, isAtolSpecial, ...props}) {
    const [visibleSelectOfferModal, setVisibleSelectOfferModal] = useState(false);
    const [detailSubscribe, setDetailSubscribe] = useState(service_item?.id);
    const [packageDetail, setPackageDetail] = useState(null);
    const [form] = Form.useForm();
    const {time, time_machine} = useUser();
    const [totalCost, setTotalCost] = useState(0)
    const [changeData, setChangeData] = useState(null)

    const onFinishForm = (data) => {
        data['package'] = packageDetail?.id;
        if (data?.prolong_date) {
            data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        }

        if (data && !isAtolSpecial) {
            new SubscribeServiceGroup(service_item?.group).create(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
                message.success('Создан заказ на смену.')
            })
        }

        if (data && isAtolSpecial) {
            new AtolSubscribeGroupService(service_item?.group).change_after_revenue_correction(data).then(res => {
                typeof props.onFinish === 'function' && props.onFinish(data);
            })
        }

    }

    useEffect(() => {
        if (service_item) {
            new PackageService(service_item?.package_id).get().then(res => {
                setPackageDetail(res.data);
            })
        }
    }, [service_item])

    useEffect(() => {
        if (detailSubscribe?.offer) {
            new PackageService(detailSubscribe?.package_id).get().then(res => {
                setPackageDetail(res.data);
            })
        }
    }, [detailSubscribe])

    useEffect(() => {
        if (packageDetail?.id) {
            new PackageService().calcProlongDate({
                current_package_id:detailSubscribe,
                change_package_id: packageDetail.id,
                current_subscribe_id: service_item.children[0].id,
                current_offer_id: service_item.children[0].offer, })
                .then(res => {
                    form.setFieldsValue({
                        prolong_date: moment(res.data.new_settling_date),
                        count: res.data.count_packages
                    })
                    setTotalCost(res.data.total_cost)
                    setChangeData(res.data.additional_data)
                })
        }

    }, [packageDetail, form])

    function onValuesChange (changedValues, allValues) {
        if (changedValues.count || changedValues.is_recalculate !== undefined) {
            new PackageService().calcProlongDate({
                current_package_id: detailSubscribe,
                change_package_id: packageDetail.id,
                current_subscribe_id: service_item.children[0].id,
                current_offer_id: service_item.children[0].offer,
                count: allValues.count,
                is_immediately: allValues.is_recalculate
            }).then(res => {
                setTotalCost(res.data.total_cost)
                setChangeData(res.data.additional_data)
            });
        }
    };

    return (
        <Form layout='vertical' {...props} form={form} onFinish={onFinishForm} onValuesChange={onValuesChange}>
            {packageDetail &&
                <Form.Item>
                    <Descriptions column={1} title='Тариф' bordered size='small' extra={<Typography>
                        <Button type='link' onClick={() => setVisibleSelectOfferModal(true)}>Сменить тариф</Button>
                    </Typography>}>
                        <Descriptions.Item label='Наименование предложения'>{packageDetail?.name}</Descriptions.Item>
                        <Descriptions.Item label='Цена за единицу'><Money sum={packageDetail?.cost}/> </Descriptions.Item>
                    </Descriptions>
                </Form.Item>
            }
            {isAtolSpecial &&
                <Form.Item label='Дата продления'
                        name='prolong_date'
                        initialValue={moment(service_item?.settling_day)}>
                    <DatePicker defaultPickerValue={time}
                                showToday={false}
                                dateRender={current => {
                                    let css_prop = {}
                                    if (current == time) {
                                        css_prop.border = '1px solid blue';
                                    }
                                    return <div className="ant-picker-cell-inner" style={css_prop}>
                                        {current.date()}
                                    </div>
                                }} style={{width: '100%'}}

                                disabledDate={(current) => {
                                    // Can not select days before today and today
                                    return time_machine ? current < time : current && current < moment().endOf('day');
                                }}
                    />
                </Form.Item>
            }
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label='Количество:' name='count' id='count'>
                        <InputNumber size='small' min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label='Полная стоимость:'>
                        <p><Money sum={totalCost} /></p>
                    </Form.Item>
                </Col>
            </Row>
            {form.getFieldValue('is_recalculate') && <ChangePriceInfo changeData={changeData} />}
            <Form.Item label={isAtolSpecial ? 'Пересчитать цену и создать заказ' : 'Тип смены:'}
                name='is_recalculate' initialValue={false}>
                <Radio.Group>
                    <Radio disabled={true} value={true}>Применить с текущей даты до конца периода</Radio>
                    <Radio value={false}>Применить со следующего периода</Radio>
                </Radio.Group>
            </Form.Item>

            <SelectPackageModal
                visible={visibleSelectOfferModal}
                onCancel={() => setVisibleSelectOfferModal(false)}
                onSelect={(record) => {
                    setPackageDetail(record);
                    setVisibleSelectOfferModal(false);
                }}
            />
        </Form>
    )
}

export function ChangeProlongForm({service, ...props}) {
    const {time, time_machine} = useUser();
    useEffect(() => {
    }, [service])

    function onFinishForm(data) {
        data['group'] = service?.group;
        data['prolong_date'] = moment(data.prolong_date).format('YYYY-MM-DD');
        new SubscribeService().updateProlongationGroup(data).then(res => {
            message.success('Дата продления обновлена');
            typeof props.onFinish === 'function' && props.onFinish(data);
        });
    }

    return (
        <Form layout='vertical' {...props} onFinish={onFinishForm}>
            <Form.Item label='Дата продления' name='prolong_date' rules={[
                {required: true, message: 'Обязательное поле к заполнению'}
            ]}>
                <DatePicker format={'YYYY-MM-DD'} defaultValue={moment(new Date(service?.settling_day))}disabledDate={(current) => {
                    return time_machine ? current < time :current && current < moment().endOf('day');
                }}/>
            </Form.Item>
        </Form>
    )
}
