import {loadFromDb, loadFromLS, saveToDb, saveToLS} from "app/Base/Tables/FlexibleTable/SaveLoad";
import {compare_ar} from "app/lib/tools";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useList, useToggle} from "react-use";
import {Column, ColumnFactory, convert_filters, Filter, OrderBy, View} from "./Types";
import {FlexibleData} from "./FlexibleData";
import {Button, Dropdown, Space} from "antd";
import {MoreOutlined, RightOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";



const FiltersContext = React.createContext();

export function Filters({children}) {
    const {current, save, model} = useFlexible();
    const {options} = model;
    const metadata = options.filters || [];

    const [prefilters, {set, push, filter, updateAt, removeAt}]
        = useList(current.filters);

    useEffect(() => {
        set(current.filters);
    }, [current]); // Всё ОК! current помещён в Memo. За ним ведётся наблюдение

    function setFilters(flts) {
        set(flts.map(Filter.create));
    }

    function addFilter(flt) {
        const fltr = Filter.create(flt);
        push(fltr);
        return prefilters.length - 1;
    }

    function find_index(flt) {
        return prefilters.findIndex(x => (
            flt.column == x.column && flt.operator == x.operator
        ));
    }

    function updateFilter(flt, i) {
        if (typeof i === "number")
            updateAt(i, Filter.create(flt));
        else
            updateAt(find_index(flt), Filter.create(flt));
    }

    function removeFilter(flt) {
        removeAt(find_index(flt));
    }

    const values = useMemo(() => ({
        filters: current.filters,
        prefilters,
        metadata,
        // functions
        setFilters, addFilter, updateFilter, removeFilter, save,
    }), [prefilters, setFilters, metadata]);

    return <FiltersContext.Provider value={values}>
        {children}
    </FiltersContext.Provider>;
}

export function useFilters() {
    return useContext(FiltersContext);
}


const ColumnsContext = React.createContext();

export function Columns({children, columns: excols, actions, click}) {
    const {current, model} = useFlexible();
    const {options} = model;
    const metadata = options.columns || [];

    const [precolumns, {
        push: _push,
        set: _set,
        updateAt,
        sort,
        remove,
        insertAt: _iat,
    }] = useList((!!(current.columns?.length))?current.columns:(excols||[]).map(x => (new Column(metadata.find(m => (m.key == x.key)) || x))));

    const immucols = [{
        dataIndex: "collapse",
        key: "collapse",
        width: 5,
    }];

    if (actions) {
        immucols.push({
            dataIndex: "actions",
            render: (_, record) => (
                <Space>
                    <Dropdown overlay={() => (typeof actions === "function") ? actions(record) : null}
                              trigger={["click"]} placement="bottomRight">
                        <Button size="small" icon={<MoreOutlined/>}/>
                    </Dropdown>
                    {click && typeof click === "function" &&
                        <Button size={"small"}
                                icon={<RightOutlined/>}
                                onClick={() => click?.(record)}/>}
                </Space>
            ),
            width: 30,
        });
    }

    const columns = _create_table_columns();

    function _create_table_columns() {
        if (!precolumns?.length)
            setColumns(excols);

        if (current.columns.length) {
            return immucols.concat(current.columns.map(col => {
                const exc = (excols||[]).find(exc =>
                                                  (exc.dataIndex == col.name) ||
                                                  (exc.key == col.name));
                if (exc) {
                    return ColumnFactory(options).Column(exc, click);
                }

                return ColumnFactory(options).Column(col, click);
            }));
        } else if (excols?.length) {
            return (immucols.concat(
                excols.map(x => {
                    const mtd_cln = metadata.find(m => (
                            (m.key||m.dataIndex) == (x.key||x.dataIndex))) || x;
                    if (x.render && typeof x.render==="function")
                        mtd_cln.render = x.render;
                    return ColumnFactory(options).Column(mtd_cln, click);
                }),
            ));
        } else {
            return (immucols);
        }
    }

    function push(col) {
        _push(ColumnFactory(options).Column(col));
    }

    function insertAt(index, item) {
        _iat(index, ColumnFactory(options).Column(item));
    }

    function setColumns(cols) {
        if (!cols?.length)
            return;

        if (!Array.isArray(cols))
            cols = Object.values(cols);
        const cols_names = cols?.map(x => x.name||x.dataIdex||x.key);
        const precols_names = precolumns?.map(x => x.name||x.dataIdex||x.key);

        if (!compare_ar(cols_names,precols_names))
            _set(cols?.map(x => (ColumnFactory(options).Column(x))));
    }

    useEffect(() => {
        setColumns(current.columns);
    }, [current, options]);

    const values = useMemo(() => ({
        // variables
        precolumns, metadata, columns,
        // functions
        push, setColumns,
        updateAt, sort, remove, insertAt,
    }), [precolumns, columns, metadata]);

    return <ColumnsContext.Provider value={values}>
        {children}
    </ColumnsContext.Provider>;
}

export function useColumns() {
    return useContext(ColumnsContext);
}

const FlexibleTableContext = React.createContext();

export function FlexibleTable({model, actions, click, columns, title, extra, onUpdate, expandable = false, ...props}) {
    const [lsViewFCData, setLsViewFCData] = useState({});
    const [ci, set_ci] = useState(0); // current index
    const [tmp_ci, set_tmp_ci] = useState(0); // tmp current index для отложенного переключения
    const { setColumns, setFilters, setLimit } = model;

    const [viewsets,
        {
            push, set, filter, updateAt, remove, clear,
        }] = useList([]);
    // let current = viewsets[ci] || View.DEFAULT_VIEW;
    let current = viewsets[ci] || new View();
    const [orderby, setOrderby] = useState(current?.orderby || new OrderBy());
    const [upd, tgl] = useToggle();
    const [_save, save] = useToggle();

    useEffect(() => {
        loadFromDb(model.ModelName).then(r => {
            setLsViewFCData(r.data);
            if (r.data.viewsets?.length) {
                set(r.data.viewsets);
            } else
                set([View.DEFAULT_VIEW])
        });
    }, []);

    useEffect(() => {
        if (viewsets.length)
            setDataToModel();
    }, [viewsets, ci]);

    useEffect(() => {
        updateCurrent({...current, orderby});
    }, [orderby]);

    useEffect(() => {
        const viewname = loadFromLS(model.ModelName);
        if (lsViewFCData && lsViewFCData.viewsets) {
            const v = lsViewFCData.viewsets.find(x => x.name == viewname);
            const i = lsViewFCData.viewsets.indexOf(v);
            set_tmp_ci(i);
        }
    }, [lsViewFCData]);

    function setDataToModel() {
        if (current) {
            const ord = new OrderBy(current?.orderby);
            setFilters({...convert_filters(current.filters), order_by: ord.sorting});
            setLimit(current.limit);
            setColumns(current.columns);
        }
    }

    function addViewSet(view) {
        let vs = viewsets.find(x => x.name == view.name);
        if (!vs) {
            push(vs = View.NEW_VIEW);
            return vs;
        } else {
            const i = viewsets.indexOf(vs);
            tgl();
            return vs;
        }
    }

    function setCurrent(view) {
        let x, view_obj;
        switch (typeof view) {
            case "number":
                x = view;
                break;
            case "object":
                x = viewsets.indexOf(
                    viewsets.find(x => x.name == view.name));
                break;
            case "string":
                x = viewsets.indexOf(
                    viewsets.find(x => x.name == view));
                break;
        }
        set_tmp_ci((x < 0) ? viewsets.length : x || 0);

        if (view != current.view) {
            view_obj = viewsets[x];
            saveToLS(model.ModelName, view_obj.name);
        }
    }

    useEffect(() => {
        if (viewsets[tmp_ci])
            set_ci(tmp_ci);
    }, [viewsets, tmp_ci, upd]);

    useEffect(() => {
        if (_save !== undefined) {
            const ob = new OrderBy(orderby);
            updateCurrent({...current, orderby: ob});
            saveToDb(model.ModelName, {viewsets: viewsets, current: ci}).then(r => {
                setLsViewFCData(r.data);
                set(r.data.viewsets);
                setDataToModel();
            });
        }
    }, [_save]);

    function updateCurrent(view) {
        const view_obj = new View(view);
        viewsets[ci] = view_obj;
        updateAt(ci, view_obj);

        setOrderby(view_obj.orderby);
    }

    function setDefault() {
        set(viewsets.map(x => ({...x, "default": false})));
        updateCurrent({...current, "default": true});
    }

    function deleteViewSet(viewset) {
        set_ci(0);
        remove(viewsets.findIndex(x => viewset.name == x.name));
    }

    const values = useMemo(() => ({
        //variables
        viewsets, current, orderby, model, lsViewFCData,
        // functions
        addViewSet, setCurrent, setDefault,
        updateCurrent, deleteViewSet,
        setOrderby, save,
        toggleTableUpdate: model.toggleTableUpdate,
        clearViewSets: clear,
    }), [viewsets, current, orderby, model, lsViewFCData]);

    return <FlexibleTableContext.Provider value={values}>
        <Columns columns={columns}
                 click={click}
                 actions={actions}>
            <Filters>
                <FlexibleData model={model}
                              title={title}
                              expandable={expandable}
                              extra={extra}
                              onUpdate={onUpdate}
                              {...props}
                />
            </Filters>
        </Columns>
    </FlexibleTableContext.Provider>;

}


export function useFlexible() {
    return useContext(FlexibleTableContext);
}


FlexibleTable.propTypes = {
    model: PropTypes.object,
    actions: PropTypes.func,
    click: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.func,
    extra: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onUpdate: PropTypes.func,

};

export default FlexibleTable;
