import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'antd'

import styles from "./Modals.module.less"
import DocumentService from "../../../API/DocumentAPI";

export default function DocModal({link, doc_id,...props}) {

  const [base64Data, setBase64Data] = useState()

  useEffect(()=>{
    if (doc_id) {
      new DocumentService(doc_id).getBase64Data().then(r=>setBase64Data(r.data))
    }
  }, [doc_id])

  return (
    <Modal {...props} destroyOnClose={true} className={styles.modal__document}
           style={{top:'30px'}}
           footer={
             <Button onClick={()=>{
               window.open(link, '_blank', 'noopener,noreferrer')
             }}>PDF</Button>
           }>
      {base64Data &&
          <object data={"data:application/pdf;base64," + base64Data}
                  type="application/pdf"></object>}
      </Modal>
  )
}

