import React, {useEffect, useState} from "react";
import {Button, Col, Space, Row, Table} from "antd";
import {ShoppingCartOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";

import { useMap } from "react-use";
import {OrderModal} from "../../Products/Modals/OrderModal";

import { ContragentService } from "../../../API/ContragentAPI";
import {CartService} from "../../../API/OrderAPI";

import Money from "../../../Components/Accounts/utils/Money";
import useContragent from "../../Contragents/ContragentContext";


export function MarketTable({...props}) {

    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, {setAll: setModalVisible}] = useMap({visible: false});

    const [update, setUpdate] = useState(false);

    const {contragent, refreshCart} = useContragent();

    useEffect(() => {
        setLoading(true);
        new ContragentService(contragent?.id).getMarket().then(res => {
            setOffers(res.data.data);
        }).finally(() => {
            setLoading(false)
        });
    }, [update, contragent])

    function handleToBucketClick(record) {
        const data = {
            offer: record.id,
            price: record.price,
            contragent: contragent.id,
            count: 1,
            tax: 0,
        }
        new CartService(contragent.id).add_item(data);
        refreshCart();
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'описание',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'контрагент',
            dataIndex: 'contragent_name',
            key: 'contragent_name',
        },
        {
            title: 'цена',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            )
        },
        {
            title: 'налоговая ставка',
            dataIndex: 'taxes',
            key: 'taxes',
            render: (value) => {
                return value === 'NO_VAT' ? 'Без НДС' : `${value}%`
            }
        },
        {
            title: 'предоплата',
            dataIndex: 'pre',
            key: 'pre',
            render: (value) => {
                return `${value} %`
            }
        },
        {
            title: 'тип услуги',
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: 'right',
            render: (value, record) => (
                <Space disabled={false}>
                    <Button 
                        size='small' icon={<PlusOutlined />} 
                        onClick={() => {
                            setModalVisible({visible: true, item: record});
                        }}
                    />
                    <Button 
                        size='small' 
                        icon={<ShoppingCartOutlined />} 
                        onClick={() => handleToBucketClick(record)}
                    />
                </Space>
            )
        },
    ]

    return <Table
        size="small"
        columns={columns}
        dataSource={offers}
        loading={loading}
        title={() => (
            <Row justify='space-between'>
                <Col>
                    <OrderModal
                        visible={modalVisible?.visible}
                        item={modalVisible?.item}
                        onCancel={() => {
                            setModalVisible({visible: false});
                            setUpdate(p => !p)
                        }}
                    />
                </Col>
                <Col>
                    <Button icon={<ReloadOutlined/>} onClick={() => setUpdate(p => !p)}/>
                </Col>
            </Row>
        )}
        {...props}
    />
}