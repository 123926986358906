import {Form, Select} from 'antd'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import ContractService from '../../../API/ContractAPI'
import useContragent from "../ContragentContext";


export default function ContractRequestForm(props) {
    const [vendors, setVendors] = useState([])
    const [types, setTypes] = useState([])
    const params = useParams()
    const {contragent} = useContragent();

    const onFinish = (values) => {
        if (values.type && values.seller) {
            values.buyer = contragent.id;
            values.status = 0;

            new ContractService().create(values).then(() => {
                props.setIsModal(false)
            })
        }
    }

    useEffect(() => {
        new ContractService().getFormData(params.id).then((r) => {
            setVendors(r.data.buyers)
            setTypes(r.data.types)
        })
    }, [params.id])

    return (
        <Form
            layout='vertical'
            onFinish={onFinish}
            {...props}
        >
            <Form.Item label='Тип договора' name='type'>
                <Select options={types}/>
            </Form.Item>
            <Form.Item label='Вендор' name='seller'>
                <Select options={vendors}/>
            </Form.Item>
        </Form>
    )
}
