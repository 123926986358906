import axios from "../config/axios";

import BaseService from "./BaseAPI";


export default class DocumentService extends BaseService {
    MODEL = "document";
    SHOWDOC = "showdoc";

    list(params) {
        return this._get(params);
    }

    getBase64Data() {
        return this._get(this.SHOWDOC);
    }

    downloadDocument_({hash, format = "pdf"}) {
        axios.get(`/file/${hash}.${format}`, {responseType: "blob"}).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${hash}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    }

    downloadDocument(document_url) { //похоже, что это не нужно
        return axios.get(document_url, {responseType: "blob"}).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", document_url.split("/").at(-1));
            document.body.appendChild(link);
            link.click();
        }).catch(DocumentService.create_error_handler(
            "Файл документа не найден"));
    }
}

export class ReconService extends DocumentService {
    MODEL = "document/recon";
}

export class BillService extends DocumentService {
    MODEL = "document/bill";
}

export class InvoiceService extends DocumentService {
    MODEL = "document/invoice";
}

export class PayOrderService extends DocumentService {
    MODEL = "document/payorder";
}

export class RefillInvoiceService extends DocumentService {
    MODEL = "document/refill";
}

export class DebtInvoiceService extends DocumentService {
    MODEL = "document/debt";
}

