import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Layout, List, PageHeader, Typography } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom';

import TemplateForm from '../Forms/TemplateForm'
import { Scrollbars } from 'react-custom-scrollbars';
import useTemplate from '../../../Providers/TemplateProvider';
import TemplateService from '../../../API/TemplateAPI';






export default function TemplateCard () {
    const [attributesList, setAttributesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {form} = useTemplate();
    const history = useHistory();
    const params = useParams();

    const [name, setName] = useState()

    useEffect(() => {
        setLoading(true);
        if (params.id){
            new TemplateService(params.id).getTemplateAPI().then(res => {
                form.setFieldsValue(res.data);
                setName(res.data.name)
            })
        }
        // странно работает. Коряво генерирует url. Нужен рефакторинг вообще сервиса АПИ.
        // new TemplateService().attributes.list().then(res => {
        //     setAttributesList(res.data.data)
        // })
        // Поэтому просто написал метод напрямую
        new TemplateService().getPlaceholders().then(res => {
            setAttributesList(res.data.data)
        })
    }, [params])

    const routes = (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/templates'}>Шаблоны</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{params?.id ? name : 'Создание шаблона'}</Breadcrumb.Item>
        </Breadcrumb>
    );


    const onFinish = (values) => {
        new TemplateService(params.id).update_or_create(values).then((_) => {
            history.push('/templates');
        })
    }

  return (
    <Layout>
        <Layout.Content>
            <PageHeader breadcrumb={routes}>
                <Card>
                    <TemplateForm form={form} id='create_template' layout='vertical' onFinish={onFinish} />
                    <Button type='primary' htmlType='submit' form='create_template'>{params?.id ? 'Изменить': 'Создать'}</Button>
                </Card>
            </PageHeader>
        </Layout.Content>
        <Layout.Sider theme='light' width={500} style={{padding: '1em'}}>
            <Scrollbars>
                {attributesList.map(group => {
                    return <TagList
                        title={group.verbose_name}
                        key={group.model_name}
                        id={group.model_name}
                        attributes={group.attributes}
                    />
                })}
            </Scrollbars>
        </Layout.Sider>
    </Layout>
  )
}


function TagList({id, title, attributes}){

    const {replaceTag} = useTemplate()

    return (
        <Card title={title} size='small' bordered={false}>
            <List
                size='small'
                bordered={false}
                dataSource={attributes}
                renderItem={item => (
                    <List.Item onClick={() => replaceTag('create_template', 'html', `${id}.${item.field}`)}>
                       <Typography.Text code copyable>{`{{${id}.${item.field}}}`}</Typography.Text> - {item.name}
                    </List.Item>
                )}
            >
            </List>
        </Card>
    )
}
