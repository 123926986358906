import moment from 'moment';
import React from "react";

import {Typography} from "antd";

import Currency from "../../Components/Accounts/utils/Currency";
import Money from "../../Components/Accounts/utils/Money";


const dtFormat = 'DD.MM.YYYY HH:mm:ss'

function dateTime(dt, date_only = false, time_only = false, ifnull = " - ") {
    if (!dt)
        return ifnull;

    // const offst = (new Date()).getTimezoneOffset() * (-1);
    try {
        // const mdt = moment(dt).utcOffset(offst);
        const mdt = moment(dt);

        let fdt = mdt.format(dtFormat);
        if (date_only)
            fdt = mdt.format('DD.MM.YYYY');

        if (time_only)
            fdt = mdt.format('HH:mm:ss');

        return fdt;
    } catch (e) {
        return ifnull;
    }
}

function DateTime({dt, dateOnly = false, timeOnly = false, style = {}, ifnull = " - "}) {
    return <Typography.Text style={style}>{dateTime(dt, dateOnly, timeOnly, ifnull)}</Typography.Text>
}


function ContractShortDetail({contract, br}) {
    return <>
        <Typography.Text italic>{contract?.get_type_display} </Typography.Text>
        {br && <br/>}
        <Typography.Text>№ {contract?.number}</Typography.Text> от {contract?.start_date ? <DateTime dt={contract?.start_date} dateOnly /> : 'N/a'}
    </>
}

function AccountShortDetail({account}) {
    return <>
        <Currency code={account.currency} type='flag' style={{
            width: "15px",
            borderRadius: "50%",
            marginTop: "7px",
        }}/>
        <Typography.Text style={{marginLeft: 6}}>{account.number}</Typography.Text>

    </>
}

function OrderItemCountPlusMeasure({item}) {
    return <>{item?.count} {item.measure ? item.measure : 'шт'}</>
}

function ItemTax({calculated_tax, currency, percent_tax}) {
    return percent_tax === 'NO_VAT'
        ? 'Без НДС'
        : <><Money sum={calculated_tax} currency={currency}/> {`(${parseInt(percent_tax)} %)`}</>
}

export {
    ContractShortDetail,
    AccountShortDetail,
    OrderItemCountPlusMeasure,
    ItemTax,
    DateTime,
    dateTime
}
