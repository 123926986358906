import {AttributableService} from "./AttributableService";
import { error_handler } from "./BaseAPI";
import axios from "../config/axios";


export default class TemplateService extends AttributableService {
    MODEL = 'text-templates'

    getTemplateAPI() {
        return this.get()
    }

    getPlaceholders() {
        return axios.get(`${this._mk_res()}/attribute`).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

}
