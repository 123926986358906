import axios from "../config/axios";
import {AttributableService} from "./AttributableService";
import {ContragentService} from "app/API/ContragentAPI";

class OfferService extends AttributableService {
    MODEL = 'offer'

    getType() {
        return this._get("type")
    }

    getTaxes() {
        return this._get("taxes")
    }

    getFourmulas() {
        return this._get("formulas")
    }

}


class OfferContragentService extends OfferService {
    MODEL='offer'

    constructor(contragent_id) {
        super(null);
        this._ctg_id = contragent_id;
        this._ctg_service = new ContragentService(contragent_id);
    }

    list(params) {
        return super.list({params: {contragent_id: this._ctg_id, ...params}});
    }

    filter(columns, attributes, filters, page, limit, search) {
        filters.contragent_id = this._ctg_id;
        return super.filter(columns, attributes, filters, page, limit, search);
    }
}


export {
    OfferService,
    OfferContragentService
}
