import {Checkbox, Form, Input} from "antd";
import {PPMAILService} from "app/API/ContragentAPI";
import useContragent from "app/Components/Contragents/ContragentContext";
import React from "react";


export const ForwardEmailForm = ({form, ppmail}) => {
    const {contragent} = useContragent();

    function forwardMail(data) {
        const {id, ..._data} = data;
        ppmail && new PPMAILService(contragent.id, id).forwardMails(_data);
    }
    function handleSubmit(data) {
        forwardMail(data);
    }

    return (
        <Form onFinish={handleSubmit}
              form={form}
              initialValues={ppmail && {
                  id: ppmail.id,
                  emails: ppmail.emails.join(","),
              }}>
            <Form.Item hidden={true} name="id"/>

            <Form.Item
                label="Электронные адреса, через запятую."
                name="emails"
                rules={[{required: true, message: "Введите электронные адреса!"}]}
            >
                <Input
                    placeholder="Электронные адреса через запятую"
                />
            </Form.Item>
            <Form.Item
                name="is_bcc"
                valuePropName="checked"
            >
                <Checkbox>Отправить как скрытую копию (BCC)</Checkbox>
            </Form.Item>
        </Form>
    );
};
