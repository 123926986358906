import {
    DEFAULT_VIEW_NAME,
    EXPRESSION_MAP,
    NEW_VIEW_NAME,
    Operator,
    OPERATORS_TYPE,
} from "app/Base/Tables/FlexibleTable/const";
import React from "react";
import {Tag} from "antd";
import {DateTime} from "src/Components/Common/OtherComponents";
import Money from "src/Components/Accounts/utils/Money";
import {CustomLink} from "../../Components/CustomLink";

class Exception extends Error {
    constructor(message, details) {
        console.log(details);
        super(message);
    }
}

class ColumnChoicesException extends Exception {}
class FilterException extends Exception{}

class ColumnSetException extends Exception{}

class ViewAlreadyExistsException extends Exception{}

class Filter {

    column;
    operator;
    value;
    type;
    choices;

    /**
     *
     * @param column {string}
     * @param operator {string}
     * @param value {any}
     * @param type {string}
     * @param choices {Array}
     */
    constructor(column, operator, value, type, choices) {
        if ([Operator.IN, Operator.NOT_IN].includes(operator||Operator.EQUAL))
            if (!(value instanceof Array))
                value = [value];
        [this.column, this.operator, this.value, this.choices, this.type]
            = [column||"", operator||Operator.EQUAL, value, choices||[], type||"string"];
    }

    /**
     *
     * @param obj {Filter|{column, operator, value, type, choices}}
     * @returns {Filter}
     */
    static create(obj){
        if (obj instanceof Filter)
            return obj;
        else {
            const {column, operator, value, type, choices} = obj;
            return new Filter(column, operator, value, type, choices);
        }
    }

}

/**
 *
 * @param filters {Array<Filter>}
 * @returns {Object}
 */
export function convert_filters(filters) {
    return filters.map(flt => {
        const oper = EXPRESSION_MAP[flt.operator];
        const col = flt.column;
        const val = flt.value;
        if (oper && col && val !== undefined)
            return {
                [`${col}${oper}`]: val
            };
        else
            return {};
    }).reduce((res, flt) => ({...res, ...flt}), {});
}


class Column {
    name;
    type;
    label;
    choices;
    render;
    dataIndex;
    title;
    key;
    metadata;
    align;

    // constructor(obj: IColumn|IHardColumn, to?: string);
    // constructor(obj: IColumn|IHardColumn, click?: ClickFn);
    // constructor(name: string, label: string, type: string, choices: IChoice[], to?: string);
    // constructor(name: string, label: string, type: string, choices: IChoice[], click?: ClickFn);

    constructor() {
        if ([1,2].includes(arguments.length)) {
            const obj = arguments[0];

            if (obj && 'name' in obj) {
                [this.name, this.type, this.label, this.choices] =
                    [obj.name, obj.type, obj.label, obj.choices];
                if (this.type || obj.render)
                    this.render = obj.render || make_renderer(this, arguments[1]);
            } else if (obj && 'title' in obj) {
                [this.name, this.type, this.label, this.choices] =
                    [obj.dataIndex||obj.key, obj.type, obj.title, obj.choices];
                if (this.type || obj.render)
                    this.render = obj.render || make_renderer(this, arguments[1]);
            } else
                throw new ColumnSetException(`Неправильные входные данные для создания колонки`);

        } else if ([4,5].includes(arguments.length)) {
            this.name = arguments[0];
            this.label = arguments[1];
            this.type = arguments[2];
            this.choices = arguments[3];
            if (this.type)
                this.render = make_renderer(this, arguments[4]);
        } else
            throw new ColumnSetException("Неправильные входные данные для создания колонки");
        this.dataIndex = this.name;
        this.key = this.name;
        this.title = this.label;

        if (['decimal','number','integer','float','money'].includes(this.type))
            this.align = 'right';
        else
            this.align = 'left';
    }

    /**
     *
     * @returns {string|string[]}
     */
    get operators() {
        return OPERATORS_TYPE[this.type];
    }
}

function ColumnFactory(metadata) {
    function createColumn() {
        const args = arguments;
        let cln;

        if ([1,2].includes(args.length))
            cln = new Column(args[0], args[1]);
        else if ([4,5].includes(args.length))
            cln = new Column(args[0], args[1], args[2], args[3], args[4]);
        else
            throw new ColumnSetException(`Неправильные входные данные для создания колонки`);

        if (metadata && 'fields' in metadata && !cln.metadata) {
            cln.metadata = metadata;
            const fld = metadata.fields.find(x => x.name == cln.name || x.name == cln.key)

            if ([1, 2].includes(args.length)) {
                if (!cln.render)
                    cln.render = make_renderer(cln, args[1]);
            } else if ([4, 5].includes(args.length)) {
                if (!cln.render)
                    cln.render = make_renderer(cln, args[4]);
            }
            cln.choices = fld?.choices;
            cln.type = fld?.type || "string";
        }
        return cln;
    }
    return {Column: createColumn};
}


class OrderByException extends Error {}

class OrderBy {

    column; // string;
    asc; //boolean;

    // constructor();
    // constructor(column: string, asc: boolean);
    // constructor(obj: IOrderBy);
    constructor() {
        if (arguments.length == 1) {
            const obj = arguments[0];
            this.column = obj.column;
            this.asc = obj.asc;
        } else if (arguments.length == 0) {
            this.column = "";
            this.asc = true;
        } else {
            this.column = arguments[0];
            this.asc = arguments[1] || false;
        }
    }

    get sorting() {
        if (this.asc)
            return [this.column]
        else if (!this.asc)
            return [`-${this.column}`]
        else return [];
    }
}


class View {
    id;
    name;
    filters;
    columns;
    attributes;
    default;
    limit;
    orderby;

    // constructor();
    // constructor(obj: IView);

    // constructor(
    //     name: string,
    //     filters: Filter[]|IFilter[],
    //     columns: Column[]|IColumn[],
    //     attrs: Object[],
    //     dflt: boolean,
    //     limit: number|null,
    //     orderby: OrderBy|IOrderBy
    // );
    constructor() {
        if (arguments.length == 0) {
            this.name = NEW_VIEW_NAME;
            this.filters = [];
            this.columns = [];
            this.attributes = [];
            this.default = false;
            this.limit = 20;
            this.orderby = new OrderBy();
        }
        else if (arguments.length == 1) {
            const obj = arguments[0];
            this.name = obj.name;
            this.filters = obj.filters;
            this.columns = obj.columns;
            this.attributes = obj.attributes;
            this.default = obj.default;
            this.limit = obj.limit || 20;
            this.orderby = obj.orderby;
            this.id = obj.id;
        }
        else {
            this.name = arguments[0];
            this.filters = arguments[1];
            this.columns = arguments[2];
            this.attributes = arguments[3];
            this.default = arguments[4];
            this.limit = arguments[5] || 20;
            this.orderby = arguments[6];
            this.id = arguments[7];
        }
    }

    static get NEW_VIEW(){
        return new View(NEW_VIEW_NAME, [], [], [], false, null, new OrderBy());
    }

    static get DEFAULT_VIEW(){
        return new View(DEFAULT_VIEW_NAME, [], [], [], false, null, new OrderBy());
    }
}

/**
 *
 * @param column {Column}
 * @param to_click {function}
 * @returns {(function(*): *)|(function(*): *)|*|(function(*): string)|(function(*): string|string)|(function(*, *): *)}
 */
function make_renderer(column, to_click) {
    let choices = null;

    if ((!column.type || !column.choices) && column.metadata) {
        const fld = column.metadata.fields.find(x => x.name == column.name);
        column.type = fld?.type || "string";
        column.choices = fld?.choices;
    }

    if (column.choices) {
        choices = column.choices.reduce((a, b) => ({[b.value]: b.display_name, ...a}), {})
    }

    function wrap_to_link(val, record) {
        if (to_click && typeof to_click === 'function') {
            return <CustomLink onClick={() => to_click(record)}>{val}</CustomLink>
        }
        return val;
    }

    function handle_object(val){
        if (val == null) return "";
        if (typeof val === 'object')
            return JSON.stringify(val);
        else
            return val.toString();
    }

    function wrap_and_handle(val, record) {
        return wrap_to_link(handle_object(val), record)
    }

    switch (column.type) {
        case 'choice':
            return (val) => {
                if (choices) {
                    return <Tag>{choices[val]}</Tag>;
                } else {
                    return <Tag>{val}</Tag>;
                }
            }
        case 'date':
            return (val) => {
                return <DateTime dt={val} dateOnly/>;
            }
        case 'datetime':
            return (val) => {
                return <DateTime dt={val}/>;
            }
        case 'money':
            return (val) => {
                return <Money sum={val}
                              currency={undefined}
                              type={'symbol'}
                              text_color={(val < 0) ? 'red' : 'green'}/>;
            }
        case 'decimal':
            return (val) => `${val}`;
        case 'boolean':
            return (val) => {
                return val;
            }
        case 'nested object': /// !!!!
            return (val) => {
                return val?.name || val?.id || val?.external_id
            }
        case 'json':
            return (val) => (val)?JSON.stringify(val):"";
        // case 'field':
        //     return handle_object;
        default:
            // return (val: any, record: any) => val;
            return wrap_and_handle;
    }
}

export {
    Column, Filter, View, OrderBy,
    ColumnSetException, FilterException, OrderByException,
    ViewAlreadyExistsException, ColumnChoicesException, make_renderer,
    ColumnFactory
};
