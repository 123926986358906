import React, {useState, useEffect} from "react";
import {Menu, Popconfirm, Space, Tag, Divider, Button, Spin, message, Tooltip} from "antd";
import {AccountProvider} from "app/Providers/AccountProvider";
import {useHistory} from "react-router-dom";

import {Card} from "src/Base";
import {FlexibleTable} from "src/Base/Tables/FlexibleTable/FlexibleTable.js";
import ClientModal from "./Modals/ClientModal";
import {BlockingModal} from "../Common/Modal/BlockingModal";
import TransferFormModal from "./Modals/TransferModal";
import ContragentCreateButton from "./ActionButtons/ContragentCreateButton";
import {useContragentData} from "src/Data/Base";
import { ContragentService } from "src/API/ContragentAPI";

import PPModal from "app/Base/Modals/Modal";
import {convertDateTime} from "app/lib/tools";
import useUser from "app/Providers/UserProvider";
// import {message, notification} from "antd";
import {useToggle} from "react-use";
import {DateTime} from "../Common/OtherComponents";


function BillingManageModal({visible, onClose, contragent}) {

    const [loading, setLoading] = useState(false);
    const [tgl, trg] = useToggle();
    const {signalGlobalUpdate} = useUser();
    const [last_billed_day, setLastBilledDay] = useState(null);

    useEffect(() => {
        if (contragent) {
            new ContragentService(contragent.id).getLastBilledDay().then(x => {
                if (x.data.date)
                    setLastBilledDay(x.data.date);
            });
            signalGlobalUpdate();
        }
    }, [contragent, visible, loading]);

    useEffect(() => {
        if (contragent) {
            new ContragentService(contragent.id).checkBillingTask().then(({data: x}) => {
                switch (x?.state) {
                    case "STARTED":
                        setTimeout(trg, 2000);
                        // setLoading(false)
                        break;
                    case "PENDING":
                        setLoading(!!x?.running)
                        break;
                    case "SUCCESS":
                    case "REVOKED":
                    case "REJECTED":
                    case "FAILURE":
                        setLoading(false)
                        break;
                }
                // setLoading(!!x?.running);
            }).catch(ex => {
                setLoading(false);
            });
        }
    }, [tgl]);


    function click_close_day() {
        setLoading(true);
        new ContragentService(contragent.id)
            .calculateNextBillingDay()
            .then(x => trg())
            .catch(x => {
                trg();
                // notification.warn({
                //     message: "Рассчёт невозможен",
                //     details: x.data.detail
                // })
            });
    }

    return <PPModal title={""}
                    onCancel={() => {
                        trg();
                        onClose?.();
                    }}
                    footer={false}
                    destroyOnClose
                    maskClosable={!loading}
                    visible={visible || loading}>
        <Spin spinning={loading}>
            Дата последнего закрытого дня <DateTime dateOnly dt={last_billed_day}/>
            <Divider type={"horizontal"}/>
            <Button onClick={click_close_day}>
                Закрыть день
            </Button>{" : "}
            <DateTime dateOnly dt={last_billed_day && convertDateTime(last_billed_day).add(1, "days")}/>
        </Spin>
        <Divider type={"horizontal"}/>
    </PPModal>;
}


export default function ClientTable() {
    const [modalVisible, setModalVisible] = useState(false);
    const [visibleTransfer, setVisibleTransfer] = useState(false);
    const [udpate, setUpdate] = useState(false);
    const [time_modal, setTimeModal] = useState(false);

    const model = useContragentData();
    const [isModalBlock, setModalBlock] = useState(false);
    const [targetContragent, setTargetContragent] = useState();
    const history = useHistory();
    const {time_machine} = useUser();

    let columns = [
        {
            title: "Наименование", dataIndex: "name", key: "name",
        },
        {
            title: "Статус", dataIndex: "status", key: "status",
            render: (val, record) => <Tag color={val?.color}>{val?.label}</Tag>,
        },
        {
            title: "Форма деятельности", dataIndex: "type", key: "type",
            // render: (val, record) => <Tag>{val}</Tag>
        },
        {
            title: "Последний рассчитанный день", dateIndex: "last_billed_day", key: "last_billed_day",
            render: (val, rec) => <Tooltip title={rec.error_message}>
                <Tag color={rec.bill_status}>{val}</Tag>
            </Tooltip>,
        }
    ];

    // if (!time_machine) {
    //     columns.push({
    //         title: "Последний рассчитанный день", dateIndex: "last_billed_day", key: "last_billed_day",
    //         render: (val, rec) => <Tooltip title={rec.error_message}>
    //             <Tag color={rec.bill_status}>{val}</Tag>
    //         </Tooltip>,
    //     });
    // }

    function onCloseContragentModal() {
        setModalVisible(false);
        setUpdate(p => !p);
    }

    function disabledRowClassName(record){
        if (record.status.code === 2) {
          return 'disabled-row';
        }
        return '';
    };

    return <>
        <Card
            title="Контрагенты"
            extra={
                <Space size={8}>
                    <ContragentCreateButton>Создать контрагента</ContragentCreateButton>
                </Space>
            }>
            <FlexibleTable model={model}
                           columns={columns}
                           rowClassName={disabledRowClassName}
                           click={(rec) => {
                               history.push(`/contragents/${rec.id}`);
                           }}
                           actions={(record) => <Menu>
                            {record.status.code === 2 &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={'Вы уверены, что хотите восстановить контрагента?'}
                                        onConfirm={() => {
                                            new ContragentService(record.id).restore().then(res => {
                                                message.success('Контрагент восстановлен');
                                                model.toggleTableUpdate();
                                            })
                                        }}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <Menu.Item key={"restore_contragent"}>
                                            Восстановить
                                        </Menu.Item>
                                    </Popconfirm>
                            }
                            {((record.status.code === 0) || (record.status.code === 1)) && <>
                               <Menu.Item key={"set_blocking"}
                                          onClick={() => {
                                              setTargetContragent(record);
                                              setModalBlock(true);
                                          }}>
                                   Установить блокировку
                               </Menu.Item>
                               <Menu.Item key={"show_contragent"}
                                          onClick={() => {
                                              // console.log(record);
                                              history.push(`/contragents/${record.id}`);
                                          }}>
                                   Показать карточку контрагента
                               </Menu.Item>
                                <Popconfirm
                                    placement="topRight"
                                    title={'Вы уверены, что хотите удалить контрагента?'}
                                    onConfirm={() => {
                                        new ContragentService(record.id).delete().then(res => {
                                            message.success('Контрагент удален');
                                            model.toggleTableUpdate();
                                        })
                                    }}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Menu.Item danger key={"delete_contragent"}>
                                        Удалить
                                    </Menu.Item>
                                </Popconfirm>
                               {/*{!time_machine &&*/}
                                   <Menu.Item key={"calc_next_day"}
                                              onClick={() => {
                                                  setTargetContragent(record);
                                                  setTimeModal(true);
                                              }}>
                                       Рассчитать следующий пропущенный день
                                   </Menu.Item>
                            {/*}*/}

                            </>}
                           </Menu>}/>
        </Card>
        <ClientModal
            visible={modalVisible}
            title="Создание"
            // onCancel={() => setModalVisible(false)}
            onCancel={onCloseContragentModal}
        />
        <BlockingModal
            targetObject={targetContragent}
            onCancel={() => setModalBlock(false)}
            onSuccess={() => setModalBlock(false)}
            visible={isModalBlock}
        />
        <AccountProvider>
            <TransferFormModal
                visible={visibleTransfer}
                onCancel={() => setVisibleTransfer(false)}
                onSuccess={() => setVisibleTransfer(false)}
            />
        </AccountProvider>
        <BillingManageModal visible={time_modal}
                            contragent={targetContragent}
                            onClose={() => setTimeModal(false)}/>

    </>;

}
