
import { useForm } from 'antd/es/form/Form'
import React from 'react'

import Modal from '../../../Base/Modals/Modal'
import {InvoiceRefillForm} from "../Forms/InvoiceCreateForm";

export default function RefillInvoiceModal (props) {
    const [form] = useForm();

    return (
        <Modal
            btnname='Создать'
            onSuccess={() => form.submit()}
            title='Создание счёта на пополнение'
            {...props}
        >

            <InvoiceRefillForm form={form} onCancel={props.onCancel}/>
        </Modal>
    )
}
