import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {Button, Card, Col, Descriptions, message, PageHeader, Row, Tabs, Typography, Tag} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import {useForm} from "antd/es/form/Form"

import {PackageService} from '../../API/PackageAPI'

import useUser from '../../Providers/UserProvider'
import usePackage, {Package} from './PackageContext'

import Modal from "../../Base/Modals/Modal";
import {PackagePriceTable} from './PackageTable'
import { PackageModal } from './Modals/PackageModal'
import AttributesForm from "../Common/Forms/AttributesForm"


export default function PackageDetail() {

    const {is_global_permitted} = useUser();
    const {packages, setPackage} = usePackage();
    const [packageModalVisible, setPackageModalVisible] = useState(false);
    const [packageAttributes, setPackageAttributes] = useState([]);
    // const [attributeUpdate, setAttributeUpdate] = useState(true);
    const [packageModal, setPackageModal] = useState(false);
    const [update, setUpdate] = useState(true);
    const [attrs_form] = useForm();
    const params = useParams();

    function sendAttributes(props) {
        new PackageService(params?.id).attributes.update(props).then(res => {
            message.success('Атрибуты сохранены');
        })
    }

    useEffect(() => {
        let ps = new PackageService(params.id);
        Promise.all([
            ps.get()
        ]).then(([packageInfoResp]) => {
            setPackage( new Package(packageInfoResp.data))
        }).catch(err => {
            message.error(`Продукт не найден ${err.message}`)
        })
    }, [params.id, packageModalVisible, update]);

    useEffect(() => {
        let ps = new PackageService(params.id);

        ps.attributes.list().then(res => {
            setPackageAttributes(res.data.data)
        })

    }, [params]);

    return (
        <PageHeader style={{background: 'white'}}
                    title={<Typography.Title level={3}>{packages?.name}</Typography.Title>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Card size='small'>
                        <Descriptions column={1} bordered title='общая информация' size='small' extra={
                            is_global_permitted('edit_package') &&
                            <Typography.Link onClick={() => setPackageModal(true) }>
                                <EditOutlined style={{cursor: 'pointer'}} />
                                <PackageModal visible={packageModal} item={packages}
                                              onCancel={() => {setPackageModal(false); setUpdate(p => !p)}}
                                />
                            </Typography.Link>
                        }>
                            <Descriptions.Item label='Наименование'>
                                <Typography.Text>{packages?.name}</Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label='статус продукта'>
                                {packages?.status_text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Артикул'>
                                {packages?.identifier || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Единицы измерения'>
                                {packages?.measure_name || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Описание продукта'>
                                {packages?.description || 'отсутствует'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Тэги'>
                                {packages && packages.tags_as_objects
                                    ? packages.tags_as_objects.map(tag =><Tag>{tag.name}</Tag>)
                                    : 'нет тэгов'
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                {packageAttributes.map(el => {
                    return <Col xs={24}>
                        <Card key={el.codename} title={el.name} style={{marginBottom: '1em'}}>
                            <AttributesForm id={el.codename}
                                            attributes={el.attributes}
                                            form={attrs_form}
                                            onFinish={data => sendAttributes(data)}
                            />
                            <Button type='primary' htmlType='submit' form={el.codename}>Сохранить</Button>
                        </Card>
                    </Col>
                })}
            </Row>
            <Tabs>
                <Tabs.TabPane tab="Ценовые предложения входящие в пакет" key="prices">
                    <PackagePriceTable />
                </Tabs.TabPane>
            </Tabs>
            <Modal form="edit_package"
                 visible={packageModalVisible}
                 btnname={'Сохранить'}
                 onCancel={() => {
                     setPackageModalVisible(false)
                 }}
                 title={"Изменить продукт"}>
            </Modal>
        </PageHeader>
    )
}
