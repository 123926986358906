import React, {useEffect, useState} from "react";
import {Button, Dropdown, Menu, message, PageHeader, Popconfirm, Space, Table, Tag} from "antd";
import {DeleteOutlined, MoreOutlined, QuestionCircleOutlined} from "@ant-design/icons";

import {Link} from 'react-router-dom';

import useUser from "../../Providers/UserProvider";
import Permission from "../../config/PermissionRouter";
import {ProductService} from "app/API/ProductAPI";
import {ContragentService} from "app/API/ContragentAPI";
import {ProductModal} from "./Modals/ProductModal";
import {productStatusTag} from "../Common/Tags";
import {BlockingModal} from '../Common/Modal/BlockingModal'


export default function ProductTable({onProductClick}) {

    const {is_global_permitted, contragent} = useUser();
    const [modalVisible, setModalVisible] = useState({visible: false});
    const [products, setProducts] = useState([]);
    const [update, setUpdate] = useState(false);
    const [isModalBlock, setModalBlock] = useState(false)
    const [product, setProduct] = useState()

    useEffect(() => {
        if (!modalVisible?.visible) {
            new ContragentService(contragent?.id).getProducts().then(res => {
                setProducts(res.data.data.map(el => {
                    el.key = el.id;
                    return el
                }))
            })
        }
    }, [modalVisible, update]);

    function access_actions() {
        let access = [];

        function render_menu(record) {

            function delete_product() {
                new ProductService(record?.id).delete().then(res => {
                    message.success('продукт удален');
                    setUpdate(p => !p);
                })
            }

            return (
                <Menu>
                    <Popconfirm
                        title={`вы уверены, что хотите удалить продукт ${record?.name}`}
                        onConfirm={delete_product}
                        okText="Удалить"
                        cancelText="Отменить"
                        icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                    >
                        <Menu.Item icon={<DeleteOutlined/>} danger>Удалить</Menu.Item>
                    </Popconfirm>
                    <Menu.Item onClick={() => {
                        setModalBlock(true)
                        setProduct(record)
                    }
                    }>Заблокировать</Menu.Item>
                </Menu>
            )
        }
            access.push({
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 50,
                render: (value, record) => (
                    <Dropdown overlay={() => render_menu(record)} trigger={['click']}>
                        <Button size="small" icon={<MoreOutlined/>}/>
                    </Dropdown>
                )
            })
        return access
    }

    const columns = [
        ...access_actions(),
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },

        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: function (name, item) {
                if (typeof onProductClick === 'function')
                    onProductClick(item);
                return <Link to={`/contragents/${contragent.id}/products/${item.id}`}>{name}</Link>
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Код номенклатуры',
            dataIndex: 'nomenclature_code',
            key: 'nomenclature_code',
        },
        {
            title: 'Единица измерения',
            dataIndex: 'measure_name',
            key: 'measure_name',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: function (name, record) {
                if (record.active_blockings)
                    return <Tag color='red'>Заблокировано</Tag>
                return productStatusTag(name)
            }
            // ProductStatusTag,
        }
    ];

    return (
        <PageHeader
            style={{background: 'white'}} title='Продукты'
            extra={
                <Space>
                    <Button type='primary'
                            onClick={() => setModalVisible({visible: true})}>Создать продукт</Button>
                </Space>
            }
        >
            <Table
                size='small'
                columns={columns}
                dataSource={products}
                width={1000}
                // expandable={{
                //     expandedRowRender: (record) => (
                //       <ProductProvider initial_product={record}>
                //             <OfferProductTable />
                //       </ProductProvider>
                //     ),
                //     defaultExpandedRowKeys: [22, 23],
                // }}
                pagination={{pageSize: 100}}
            />

            <ProductModal visible={modalVisible?.visible} onCancel={() => setModalVisible({visible: false})}/>

            {/* <Modal form="create_product"

                   btnname={'Сохранить'}
                   onCancel={() => {setModalVisible(false)}}
                   onSuccess={() => {setModalVisible(false)}}
                   title={"Создать продукт"}>

                <ProductForm id="create_product"
                            currencies={currencies}
                            setIsModal={setModalVisible}
                />
              </Modal> */}
              <BlockingModal
                    targetObject={product}
                    onCancel={() => setModalBlock(false)}
                    onSuccess={() => setModalBlock(false)}
                    visible={isModalBlock}
                />
        </PageHeader>
    )
}
