import React, {useEffect} from "react";

import { Modal, Form, Button, message } from "antd";

import { ProductService } from "../../../API/ProductAPI";
import useUser from "../../../Providers/UserProvider";

import ProductForm from '../Forms/ProductForm';

export function ProductModal({item, ...props}) {

    const [form] = Form.useForm();
    const {contragent} = useUser();

    useEffect(() => {
        item && form.setFieldsValue(item);
    }, [item])

    const createOrUpdate = (data) => {
        data['contragent'] = contragent.id
        if(item){
            new ProductService(item?.id).update(data).then(res => {

                message.success('продукт обновлён');
                typeof props.onCancel === 'function' && props.onCancel();
            })
        } else {
            new ProductService().create(data).then(res => {
                message.success('продукт создан');
                typeof props.onCancel === 'function' && props.onCancel();
            })
        }
    }

    return (
        <Modal
            id="create_product"
            title={item ? 'Изменить продукт' : 'Создать продукт'}
            footer={
                <Button type="primary" htmlType="submit" form="create_or_update_product">
                    {item ? 'Изменить' : 'Сохранить'}
                </Button>
            }
            {...props}
        >
            <ProductForm id="create_or_update_product" form={form}
                onFinish={(d) => createOrUpdate(d)}
                edit={!!item}
                />
        </Modal>
    )
}
