import React from 'react';

import { useForm } from 'antd/es/form/Form';
import Modal from '../../../Base/Modals/Modal';
import ReconCreateForm from "../Forms/ReconCreateForm";
import BillCreateForm from "../Forms/BillCreateForm";
import InvoiceCreateForm from "../Forms/InvoiceCreateForm";

const TYPES = {
  bill: 'УПД',
  reconciliation: 'Акт сверки',
  invoice: 'Платёжное поручение'
}

export default function DocumentCreateModal({visible, updated,...props}) {
  const [form] = useForm();

  return (
    <Modal  btnname='Создать'
            onSuccess={() => {
              form.submit();
              (typeof updated==='function') && updated();
            }}
            // title={`Создать ${docType?TYPES[docType]:""}`}
            // visible={operVisible}
            // onCancel={()=>setOperVisible(false)}
            {...props}>
      {/* { (docType=='reconciliation') &&
          <ReconCreateForm form={form} setIsModal={props.setIsModal}/> ||
        (docType=='bill') &&
          <BillCreateForm form={form} setIsModal={props.setIsModal}/> ||
        (docType=='invoice') &&
          <InvoiceCreateForm form={form} setIsModal={props.setIsModal}/> } */}
    </Modal>
  )
}

