import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {useList} from 'react-use'

import {Button, Dropdown, Input, Menu, message, notification, Row, Table, Typography} from "antd";
import {EditOutlined, MoreOutlined} from "@ant-design/icons";
import {OrderService} from "src/API/OrderAPI";
import RevenueService from "../../../API/RevenueAPI";
import {InvoiceService} from "app/API/DocumentAPI";

import Money from "../../Accounts/utils/Money";
import {orderStatusTag, OrderTypeTag} from "../../Common/Tags";
import {DateTime, ItemTax, OrderItemCountPlusMeasure} from "../../Common/OtherComponents";
import {PartPayOrderItemModal, PartPayOrderModal} from "../Modals/OrderModal";
import {OrderDetail} from '../Cards/OrderCards';

import {BlockingModal} from '../../Common/Modal/BlockingModal';

import useAccount from "../../../Providers/AccountProvider";
import useContragent from "app/Components/Contragents/ContragentContext";
import {useOrdersData} from "src/Data/Base";
import {OfferModal} from "src/Components/Contragents/Modals/OfferModal";
import FlexibleTable from "src/Base/Tables/FlexibleTable/FlexibleTable.js";
import useUser from "app/Providers/UserProvider";
import {SimplPerm} from "app/config/PermissionRouter";
import {OrderProvider} from 'src/Providers/OrderProvider';
import { useOrder } from "src/Providers/OrderProvider";

export function OrdersRouter(){

    return (
        <OrderProvider>
            <Switch>
                <Route exact path={"/contragents/:contragent_id/orders/:order_id"}>
                    <OrderDetail />
                </Route>
                <Route exact path={"/contragents/:contragent_id/orders"}>
                    <OrdersTable />
                </Route>
            </Switch>
        </OrderProvider>
    )
}

export default function OrdersTable() {
    // Основная таблица с заказами

    const {contragent} = useContragent();
    const [ordersList, {updateAt}] = useList([]);
    const {is_permitted} = useUser();

    const [tableUpdated, setTableUpdated] = useState(0);

    const [partPayModalOpen, setPartPayModalOpen] = useState(false);
    const [partPayOrder, setPartPayOrder] = useState({});
    const history = useHistory();

    // const [orderDetail, setOrderDetail] = useState(null);

    const {toggle} = useAccount();
    const model = useOrdersData({contragent});

    const columns = [
        {title: "Номер", dataIndex: 'number', key: 'number'},
        {
            title: "Дата заказа", dataIndex: 'date', key: 'date',
            render: (val) => <DateTime dateOnly dt={val}/>
        },{
            title: "Статус", dataIndex: 'status', key: 'status',
            render: (val, rec) => orderStatusTag(val)
        },{
            title: "Тип заказа", dataIndex: 'type', key: 'type',
            render: (val, rec) => OrderTypeTag(val)
        },{
            title: "Сумма", dataIndex: 'cost', key: 'cost',
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>
        },{
            title: "НДС", dataIndex: 'tax', key: 'tax',
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>
        },{
            title: "Оплачено", dataIndex: 'paid_amount', key: 'paid_amount',
            render: (val, rec) => <Money sum={val} currency={rec.currency}
                                         text_color={(val===rec.cost)?"success":(val===0)?"":(val<rec.cost)?"warning":""}/>
        },{
            title: "Реализовано", dataIndex: 'realized', key: 'realized',
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>
        },
    ]
    const [isModalBlock, setModalBlock] = useState(false)
    const [marketModal, setMarketModal] = useState({visible: false});


    function updateList(item) {
        for (let i = 0; i < ordersList.length; i++) {
            if (item.id === ordersList[i].id) {
                updateAt(i, item)
            }
        }
    }

    /* Всё это говнище надо убрать в useOrderData */
    function createInvoice(order) {
        new InvoiceService().create({
            order: order.id,
            account: order.seller_contract_account_id
        }).then(res => {
            notification.success({
                message: `Создан документ ${res.data.name}`,
                description: `Создан счёт № ${res.data.number}. Счёт доступен во вкладке Финансовые документы`
            })
            toggle();
        })
    }

    function payOrderAPI(order) {
        new OrderService(order.id).pay({}).then(res => {
            setTableUpdated(tableUpdated + order.id + res.data._updated)
            toggle();
            notification.success({message: `Оплата произведена`})
        })
    }

    function refundAPI(order) {
        new OrderService(order.id).refund().then(res => {
            setTableUpdated(tableUpdated + order.id + res.data._updated)
            toggle();
            notification.success({message: `Возврат оформлен`})
        })
    }

    function changeStatusToProcessingAPI(order) {
        new OrderService(order.id).changeStatusToProcessing().then(res => {
            setTableUpdated(tableUpdated + order.id + res.data._updated)
            toggle();
            notification.success({message: `Заказ в процессе`})
        });
    }

    function createRevenue(order) {
        let _order = null;
        new OrderService(order.id).get().then(res => {
            _order = res.data;

            new RevenueService().create({
                name: `реализация по заказу ${_order.number}`,
                status: "template",
                contract: _order.contract.id,
                order: order.id
            }).then(r => {
                setTableUpdated(tableUpdated + 1);
                notification.success({
                    message: 'Реализации созданы',
                    description: `Строки реализации созданы`
                });
                toggle();
            }).catch(RevenueService.warning_or_error_handler);
        });
    }

    function cancelOrderAPI(order) {
        new OrderService(order.id).cancel().then(res => {
            setTableUpdated(tableUpdated + order.id + res.data._updated)
            toggle();
            notification.success({message: `Заказ отменен`})
        });
    }

    /*вот посюда*/

    function actions(record) {
        return <Menu>
            <Menu.Item key='9' onClick={() => {
                // setOrderDetail(record)
            }}>
                Детали/Редактировать
            </Menu.Item>

            {record && <Menu.Item key='1' onClick={() => createInvoice(record)}>
                Запросить счёт
            </Menu.Item>}

            {record && contragent && is_permitted('application.order.access') && <>
                <Menu.Item key='2' disabled={record.status === 'complete' || record.status === 'cancel' || record.status_pay == 'paid'}
                           onClick={() => payOrderAPI(record)}>
                    Оплатить полностью
                </Menu.Item>
                <Menu.Item key='2' disabled={record.status !== 'new'}
                           onClick={() => refundAPI(record)}>
                    Вернуть оплату на ЛС
                </Menu.Item>
                <Menu.Item key='3' disabled={record.status === 'complete' || record.status === 'cancel' || record.status_pay == 'paid'}
                           onClick={() => {
                               setPartPayModalOpen(true);
                               setPartPayOrder(record)
                           }}>
                    Частичная оплата
                </Menu.Item>
                <Menu.Item key='4' disabled={record.status !== 'new'}
                           onClick={() => changeStatusToProcessingAPI(record)}>
                    Изменить статус "В процессе"
                </Menu.Item>
                <Menu.Item key='5' onClick={() => createRevenue(record)}>
                    Создать реализацию
                </Menu.Item>

                <Menu.Item key='block' onClick={() => {
                    setModalBlock(true);
                    // setOrderDetail(record)
                }}>
                    Заблокировать
                </Menu.Item>

            </>
            }

            <Menu.Item key='8' disabled={record.status === 'cancel' || record.status === 'complete'}
                       onClick={() => cancelOrderAPI(record)}>
                Отменить заказ
            </Menu.Item>

        </Menu>
    }

    const handleRowClick = (record) => {
        history.push(`/contragents/${contragent?.id}/orders/${record.id}`);
      };

    return <>
            <FlexibleTable model={model}
                           click={(record) => handleRowClick(record)}
                           actions={actions}
                           columns={columns}
                           extra={
                                <SimplPerm perms={'application.order.create'}>
                                    <Button onClick={() => {setMarketModal({visible: true})}}>
                                        Создать заказ
                                    </Button>
                                </SimplPerm>
                           }/>
            <PartPayOrderModal
                item={partPayOrder}
                visible={partPayModalOpen}
                onCancel={() => setPartPayModalOpen(false)}
            />
            <OfferModal visible={marketModal?.visible}
                        onCancel={() => {
                            setMarketModal({visible: false})
                        }}/>
            <BlockingModal
                // targetObject={orderDetail}
                onCancel={() => setModalBlock(false)}
                onSuccess={() => setModalBlock(false)}
                visible={isModalBlock}
            />
        </>

}

export function EditableItem({item, value, changeOrderItem, enableButtons, type}) {
    const [edit, setEdit] = useState()
    const [newValue, setNewValue] = useState(value)

    function onEditOk() {
        changeOrderItem(item.id, newValue);
        setEdit(false);
    }

    return <Row justify="space-around">
        {enableButtons
            ? edit
                ? <>
                    <Button onClick={onEditOk}>Ок</Button>
                    <Button onClick={() => setEdit(false)}>отмена</Button>
                    <Input defaultValue={value} value={newValue}
                           style={{width: 75}} onChange={(e) => setNewValue(e.target.value)}/>
                </>
                : <>
                    <EditOutlined onClick={() => setEdit(true)}/>
                    {type === 'count' && <OrderItemCountPlusMeasure item={item}/>}
                    {type === 'price' && <Money sum={value} type={'symbol'} currency={810}/>}
                </>
            : value
        }
    </Row>
}

export function OrderItemTable({order_id, viewMode, loading, ...props}) {

    const [orderItems, setOrderItems] = useState([]);
    const [payOrderItemModal, setPayOrderItemModal] = useState({visible: false});
    const [update, setUpdate] = useState(false);
    const {contragent} = useContragent();
    const {order, toggle} = useOrder();

    // const [counterModalVisible, setCounterModalVisible] = useState(false);
    // const [billingModalVisible, setBillingModalVisible] = useState(false);
    // const [orderItem, setOrderItem] = useState(null);

    function deleteOrderItem(item_id) {
        if (order.status === 'new' || order.status === 'processing')
            new OrderService(order_id).deleteItem(item_id, {
                contragent: contragent.id,
                item: item_id
            }).then(res => {
                // setOrderDetail(res.data.order)
                setOrderItems(res.data.items)
            });
        else message.error('Статус заказа не Новый.')
    }

    function changeOrderItemCount(item_id, new_item_count) {
        new OrderService(order_id).changeItem({
            contragent: contragent.id,
            item: item_id,
            new_item_count: new_item_count
        }).then(res => {
            // setOrderDetail(res.data.order)
            setOrderItems(res.data.items)
        })
    }

    function changeOrderItemPrice(item_id, new_item_price) {
        new OrderService(order_id).changeItem({
            contragent: contragent.id,
            item: item_id,
            new_item_price: new_item_price
        }).then(res => {
            // setOrderDetail(res.data.order)
            setOrderItems(res.data.items)
        })
    }

    const menu_actions = (record) => (
        <Menu>
            <Menu.Item key={'add_realize'}>
                <Typography.Text
                    onClick={() => {
                        new RevenueService().add_item({
                            "order_item": record.id,
                            "amount": record.cost
                        })
                            .then(r => {
                                if (r.data.id) {
                                    notification.success({
                                        "message": "Добавлено в реализацию",
                                        "description": "Позиция заказа добавлена в реализацию"
                                    });
                                } else {
                                    notification.warning({
                                        "message": "Предупреждение",
                                        "description": "Что-то полшо не так"
                                    });
                                }
                            }).catch(RevenueService.error_handler);
                    }}
                >Добавить в реализацию</Typography.Text>
            </Menu.Item>
            <Menu.Item key={'pay_order_item'} onClick={() => setPayOrderItemModal({visible: true, item: record})}>
                Оплата
            </Menu.Item>
            <Menu.Item key={'refund'}>
                Возврат
            </Menu.Item>
            <Menu.Item danger disabled key={'delete_order_item'}>
                Удалить
            </Menu.Item>
        </Menu>
    )

    const columns = [
        {
            title: '', dataIndex: 'actions', key: 'actions', align: 'center', width: 10, render: (_, record) => {
                return <Dropdown overlay={menu_actions(record)} trigger={['click']} placement='bottomLeft'>
                    <Button type='text' icon={<MoreOutlined/>}/>
                </Dropdown>
            }
        },
        {title: '№', dataIndex: 'id', key: 'id', align: 'center'},
        {
            title: 'Продавец',
            dataIndex: 'seller',
            key: 'seller', align: 'left'
        },
        {title: 'Продукт', dataIndex: 'product_name', key: 'product_name', align: 'left'},
        {title: 'Услуга', dataIndex: 'product_type_name', key: 'product_type_name', align: 'center'},
        {
            title: 'Количество', dataIndex: 'count', align: 'center', key: 'count',
            render: (value, record) => {
                if (viewMode === 'edit')
                    return <EditableItem
                        type={'count'}
                        key={record.id}
                        item={record}
                        value={value}
                        changeOrderItem={changeOrderItemCount}
                        enableButtons={order.status !== 'complete' && order.status !== 'cancel'}
                    />
                else return <OrderItemCountPlusMeasure item={record}/>
            }
        },
        {
            title: 'Цена за единицу', dataIndex: 'price', align: 'right', key: 'price',
            render: (price, record) => {
                if (viewMode === 'edit')
                    return <EditableItem
                        type={'price'}
                        key={record.id}
                        item={record}
                        value={price}
                        changeOrderItem={changeOrderItemPrice}
                        enableButtons={order.status !== 'complete' && order.status !== 'cancel'}
                    />
                else return <Money sum={price} type={'symbol'} currency={810}/>
            }
        },
        {
            title: 'НДС', dataIndex: 'tax', key: 'tax', align: 'right', render: (value, record) => (
                <ItemTax calculated_tax={value} currency={record?.currency} percent_tax={record.percent_tax}/>
            )
        },
        {
            title: 'Сумма', dataIndex: 'total_price', key: 'total_price', align: 'right', render: (value, record) => (
                <Money sum={value} currency={record?.currency}/>
            )
        },
        {
            title: 'Оплачено', dataIndex: 'paid', key: 'paid', align: 'right', render: (value, record) => (
                <Money sum={value} currency={record?.currency}/>
            )
        },
        {
            title: 'Скидка', dataIndex: 'discount', key: 'discount', align: 'right', render: (value, record) => (
                <Money sum={value} currency={record?.currency}/>
            )
        },
        {
            title: 'Реализовано', dataIndex: 'realized', key: 'realized', align: 'right',
            render: (value, record) => <Money sum={value} currency={record?.currency}/>
        },
    ]


    useEffect(() => {
        if (order_id) {
            new OrderService(order_id).get_items().then(res => {
                setOrderItems(res.data.data)
            })
        } else {
            new OrderService().list().then(res => {
                setOrderItems(res.data.data)
            })
        }
    }, [order_id, update])

    return (
        <Table
            size='small'
            pagination={false}
            loading={loading}
            title={() => (<>
                <PartPayOrderItemModal
                    visible={payOrderItemModal?.visible}
                    item={payOrderItemModal?.item}
                    onCancel={() => setPayOrderItemModal({visible: false})}
                    onSuccess={() => {
                        setPayOrderItemModal({visible: false});
                        toggle();
                    }}
                />
            </>)}
            dataSource={orderItems}
            columns={columns}
        />
    )
}


export function OrderItemsPlainTable({orderItems}) {
    const columns = [
        {title: '№', dataIndex: 'id', key: 'id', align: 'center'},
        {
            title: 'Продавец',
            dataIndex: 'seller',
            key: 'seller', align: 'left'
        },
        {title: 'Продукт', dataIndex: 'product_name', key: 'product_name', align: 'left'},
        {title: 'Услуга', dataIndex: 'product_type_name', key: 'product_type_name', align: 'center'},
        {
            title: 'Количество', dataIndex: 'count', align: 'center', key: 'count',
            render: (value, record) => {
                return <OrderItemCountPlusMeasure item={record}/>
            }
        },
        {
            title: 'Цена за единицу', dataIndex: 'price', align: 'right', key: 'price',
            render: (price, record) => {
                return <Money sum={price} type={'symbol'} currency={810}/>
            }
        },
        {
            title: 'НДС', dataIndex: 'tax', key: 'tax', align: 'right', render: (value, record) => (
                <ItemTax calculated_tax={value} currency={record?.currency} percent_tax={record.percent_tax}/>
            )
        },
        {
            title: 'Сумма', dataIndex: 'total_price', key: 'total_price', align: 'right', render: (value, record) => (
                <Money sum={value} currency={record?.currency}/>
            )
        },
        {
            title: 'Скидка', dataIndex: 'discount', key: 'discount', align: 'right', render: (value, record) => (
                <Money sum={value} currency={record?.currency}/>
            )
        },
        {
            title: 'Реализовано', dataIndex: 'realized', key: 'realized', align: 'right',
            render: (value, record) => <Money sum={value} currency={record?.currency}/>
        },
    ]

    return (
        <Table
            size='small'
            pagination={false}
            // loading={loading}
            dataSource={orderItems}
            columns={columns}
        />
    )
}
