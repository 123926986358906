import {useKeycloak} from "@react-keycloak/web";
import {Affix, Button, Card, Col, Layout, Row} from "antd";
import {BaseAuthService, KeycloakAuthService} from "app/API/AuthAPI";
import API_ from "app/API/ModuleAPI";

import priceplan from "app/img/priceplan.png";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import AutorizationService from "../../config/autorization_backends";
import {notification} from "antd";

import useSystemInfo from "../../Providers/SystemProvider";
import {Footer} from "../Layout";
import {SigninForm} from "./Forms/LoginForm";
import "./style.css";


export default function Login({admin}) {

    const {system} = useSystemInfo();

    return (
        <Layout style={{
            width: "100%",
            background: "#fed74d",
            height: "100vh",
        }}>
            {/* Вынести svg в отдельный файл */}
            <svg id="low_poly" viewBox="0 0 1600 600"
                 preserveAspectRatio="xMidYMid slice">
                <defs>
                    <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="0"
                                    color-interpolation="sRGB">
                        <stop id="stop2a" offset="0%" stop-color="#63ADD0"/>
                        <stop id="stop2b" offset="100%" stop-color="#64656a"/>
                    </linearGradient>
                </defs>
                <rect id="rect1" x="0" y="0" width="1600" height="600" stroke="none"
                      fill="url(#grad1)"/>
                <rect id="rect2" x="0" y="0" width="1600" height="600" stroke="none"
                      fill="url(#grad2)"/>
            </svg>
            <Row justify="center">
                <Col xs={12} md={10} xl={6} style={{marginTop: "10%"}}>
                    {/*<KeyCloakBackendLogin/>*/}
                    {(admin || !system?.keycloak?.enabled) && <BasePriceplanLogin/>}
                    {!admin && system?.keycloak?.enabled &&
                        !system?.keycloak?.keycloak_type_integration && <KeyCloakBackendLogin/>}
                    {!admin && system?.keycloak?.enabled &&
                        system?.keycloak?.keycloak_type_integration && <KeyCloakPublicLogin/>}
                </Col>
            </Row>
            <Affix style={{position: "absolute", bottom: 20, right: "20%"}}>
                <Footer/>
            </Affix>
        </Layout>);
}


function BasePriceplanLogin({...props}) {

    const history = useHistory();
    const {system} = useSystemInfo();
    const [atol, setAtol] = useState(false);

    useEffect(() => { // это сраный костыль, но пока так
        const API = new API_();
        API.list(res => {
            if (res.data.data.includes('atol')) {
                setAtol(true);
            }
        });
    }, []);

    function onLogin(data) {
        new AutorizationService(BaseAuthService).login(data).then(res => {
            history.push("/");
        }).catch(err => {
            notification.error({message: "Авторизация провалена"})
        });
    }

    return <Card {...props}>
         {/* <img
            src={priceplan}
            alt="priceplan"
            style={{padding: "1em", width: "100%"}}
        /> */}
        {system?.logo ?
            <img src={system.logo}
                 alt='logo' style={{padding: "1em", width: "100%"}} /> :
            <img
                src={priceplan}
                alt="priceplan"
                style={{padding: "1em", width: "100%"}}
            />
        }
        <SigninForm id='login_form' onFinish={onLogin}/>
        <Row gutter={16}>
            <Col xs={12}>
                <Button type="primary" htmlType="submit" style={{width: "100%"}}
                        form="login_form">Войти</Button>
            </Col>
            <Col xs={12}>
                <Link to="/register">
                    <Button style={{width: "100%"}}>Регистрация</Button>
                </Link>
            </Col>
        </Row>
        <Row justify="center">
            <Col>
                <Button type="link" className="login-form-forgot">
                    Забыли пароль?
                </Button>
            </Col>
        </Row>
    </Card>;
}

function KeyCloakPublicLogin({...props}) {
    const {keycloak} = useKeycloak();
    const history = useHistory();
    const {system} = useSystemInfo();

    useEffect(() => {
        if (keycloak.authenticated) {
            localStorage.setItem("jwt_sec_token_v1", JSON.stringify({
                                                                        access_token: keycloak.token,
                                                                        refresh_token: keycloak.refreshToken,
                                                                        token_type: "Keycloak",
                                                                    }));
            new AutorizationService(KeycloakAuthService).test().then(r => {
                console.log("OK");
            }).catch(err => {
                notification.error({
                    message: "Аутентификация провалена",
                    description: "Пользователь не смог пройти аутентификацию"
                })
            })
        }
    });

    return <Card {...props}>
        <Row align={"center"}>
            <img
                src={priceplan}
                alt="priceplan"
                style={{padding: "1em", width: "100%"}}
            />
        </Row>
        <Card size="small" title={
            <Row align={"center"}>
                {/* <img
                    src={priceplan}
                    alt="priceplan"
                    style={{padding: "1em", width: "100%"}}
                /> */}

            </Row>
        }>
            <Button
                type="primary"
                htmlType="submit"
                form="signin_keycloak_form"
                onClick={keycloak.login}
                style={{width: "100%"}}
            >
                Войти
            </Button>
        </Card>
    </Card>;
}

function KeyCloakBackendLogin({...props}) {
    const history = useHistory();
    const {system} = useSystemInfo();

    function onLogin(data) {
        new AutorizationService(KeycloakAuthService).login(data).then(res => {
            history.push("/");
        }).catch(err => {
            notification.error({
                message: "Авторизация провалена",
            })
        });
    }
    return <Card {...props}>
        <Row align={"center"}>
            {/* <img
                    src={priceplan}
                    alt="priceplan"
                    style={{padding: "1em", width: "100%"}}
                /> */}
                {system?.logo ?
                    <img src={system.logo}
                        alt='logo' style={{padding: "1em", width: "100%"}} /> :
                    <img
                        src={priceplan}
                        alt="priceplan"
                        style={{padding: "1em", width: "100%"}}
                    />
                }
            </Row>
            <SigninForm id="signin_keycloak_form" onFinish={onLogin}/>
            <Button
                type="primary"
                htmlType="submit"
                form="signin_keycloak_form"
                style={{width: "100%"}}
            >
                Войти
            </Button>
    </Card>
}
