import React, {useEffect, useState} from "react";
import Modal from "../../Modals/Modal";
import {Button, Col, Divider, Form, notification, Radio, Row, Select, Space, Spin, Typography} from "antd";
import {useFilters, useFlexible, useColumns} from "./FlexibleTable";
import {Filter, View} from "./Types";
import {FilterRow, Pagination, RowOrderBy, TransferDnD} from "./FormComponents";
import {PlusOutlined} from "@ant-design/icons";
import {Card} from "app/Base";


export function FiltersContainer() {
    const {filters, prefilters, addFilter, removeFilter, updateFilter} = useFilters();
    const [orStatementValue, setOrStatementValue] = useState(false);

    return <>
        {/*<Row>*/}
        {/*    <Form.Item name={"or_statement"}>*/}
        {/*        <Radio.Group onChange={(e) => setOrStatementValue(e.target.value)}*/}
        {/*                     value={orStatementValue}>*/}
        {/*            <Radio value={false}>Все условия верны</Radio>*/}
        {/*            <Radio value={true}>Хотя бы одно условие верно</Radio>*/}
        {/*        </Radio.Group>*/}
        {/*    </Form.Item>*/}
        {/*</Row>*/}

        <Row style={{padding: 10}}>
            <Button icon={<PlusOutlined/>} onClick={() => {
                addFilter(new Filter());
            }}/>
        </Row><Row>
        {prefilters.map((flt, ind) => (
            <FilterRow filter={flt} index={ind}/>
        ))}
    </Row></>;
}

export function ViewEditForm() {
    return <Form layout="vertical">
        <TransferDnD/>
        <Divider style={{margin: 10, padding: 0}}/>

        <Pagination/>
        <RowOrderBy/>

        <Divider style={{margin: 10, padding: 0}}/>
        <FiltersContainer/>
    </Form>;
}


export function ViewMngPanel() {
    const {
        current, viewsets, setCurrent, addViewSet,
        setDefault, deleteViewSet,
        updateCurrent, save,
    } = useFlexible();
    const {precolumns} = useColumns();
    const {filters, prefilters} = useFilters();

    function onSelect(value) {
        if (value) {
            setCurrent(value);
        } else {
            // addViewSet(View.NEW_VIEW);
            // setCurrent(NEW_VIEW_NAME);
        }
    }

    function saveView() {
        updateCurrent({
            ...current,
            filters: prefilters,
            columns: precolumns,
        });
        save();
    }

    function onDelete() {
        deleteViewSet(current);
        // notification.info({message: "Представление удалено"});
    }

    function onChange(val) {
        if (viewsets.filter(x => x.name != current.name).find(x => x.name == val))
            notification.error({message: "Представление с таким именем уже существует"});
        else
            updateCurrent({...current, name: val});
    }

    return <Card size="small">
        <Row justify="space-around">
            <Space>
                <Select value={current.name}
                        key={current.name}
                        onChange={onSelect}
                        style={{minWidth: 200}}>
                    {viewsets.map(f =>
                        <Select.Option value={f.name}>{f.name} {f.default && "По умолчанию"}</Select.Option>,
                    )}
                </Select>
                <Button onClick={() => {
                    const vs = addViewSet(View.NEW_VIEW);
                    setCurrent(vs);
                }}
                        icon={<PlusOutlined/>} type={"primary"}/>
            </Space>
            <Typography.Text style={{paddingTop: 5}}
                             editable={{onChange}}>
                {current?.name}
            </Typography.Text>
            <Button onClick={saveView}>Сохранить</Button>
            <Button onClick={onDelete} type="danger"
                    disabled={(viewsets.length == 1 || current.name == "Default")}>
                Удалить
            </Button>
        </Row>
    </Card>;
}


export function ViewSetModal({visible, onSuccess, onCancel}) {

    const {save, current, updateCurrent, toggleTableUpdate} = useFlexible();
    const {precolumns} = useColumns();
    const {filters, prefilters} = useFilters();


    function _onSuccess() {
        updateCurrent({
            ...current,
            filters: prefilters,
            columns: precolumns,
        });
        save();
        toggleTableUpdate();
        onSuccess?.();
    }

    return <Modal onSuccess={_onSuccess}
                  width={"70%"}
                  visible={visible}
                  title="Настройки представления"
                  onCancel={onCancel}
                  destroyOnClose={false}
                  btnname={"Сохранить и показать"}>
        <Spin spinning={false}>
            <ViewMngPanel/>
            <Divider style={{margin: 10, padding: 0}}/>
            <ViewEditForm/>
        </Spin>
    </Modal>;

}
