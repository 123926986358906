import { Button } from "antd";
import React, { useState } from "react";
import TransferFormModal from "../../Contragents/Modals/TransferModal";


export default function ActionTransferButton({children, onSuccess, ...props}){
    const [visible, setVisible] = useState(false);

    function successTransfer(data){
        setVisible(false);
        if(typeof onSuccess === 'function'){
            onSuccess(data)
        }
    }

    return <>
        <Button {...props} onClick={() => setVisible(true)}>
            {children}
        </Button>
        <TransferFormModal
            visible={visible}
            onCancel={() => setVisible(false)}
            onSuccess={successTransfer}
        />
    </>
}
