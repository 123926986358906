import React, {useContext, useMemo, useState} from "react";
// import { AccountService } from "../API/AccountAPI";


const CartContext = React.createContext();

export function CartProvider({children}) {
    // Контекст хранилища по работе с корзиной. В этапе разработке. Необходимо переделать корзину

    const [infoCart, setInfoCart] = useState(null);
    const [preOrders, setPreOrders] = useState([]);

    const memoedValue = useMemo(
        () => ({
            infoCart,
            preOrders,
            setInfoCart,
            setPreOrders
        }),
        [infoCart, preOrders]
    );

    return (
        <CartContext.Provider value={memoedValue}>
            {children}
        </CartContext.Provider>
    );
}

export default function useCart() {
    return useContext(CartContext);
}
