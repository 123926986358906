import BaseService from './BaseAPI'
import {AttributableService} from "./AttributableService";

import {message} from "antd";

class ProductService extends AttributableService {
    MODEL = 'product'

    getStatuses() {
        return this._get('statuses')
    }

    getContragents() {
        return this._get('contragents')
    }

    updateContragents(data) {
        return this._patch('contragents', data)
    }

    getFreeConts() {
        return this._get('free-conts')
    }

    getPrices() {
        return this._get(`offers`)
    }

    getTaxes() {
        return this._get(`taxes`)
    }

    // getAttributesValue() {
    //     return this._get("attributes")
    // }

    deletePrice(id) {
        this._check_id()
        return this._delete(`offers/${id}`)
    }

    patchPrice(price_id, data) {
        return this._patch(`offers/${price_id}`, data)
    }

    createPrice(data) {
        return this._post(`offers`, data)
    }

    createOrUpdatePrice(price_id, data) {
        if (price_id)
            return this.patchPrice(price_id, data)
        else
            return this.createPrice(data)
    }

    setNomenclature (data) {
        return this._post('set-nomenclature', data).then(res => {
            message.success(`Номенклатура обновлена`);
            return Promise.resolve(res);
        }).catch(err => {
            if (err.response.data && err.response.data.detail) {
                message.error(err.response.data.detail)
            }
            return Promise.reject(err)
        })
    }
}


class ProductOfferService extends BaseService{
    MODEL = 'product'

    constructor(product_id){
        super()
        this.MODEL = `product/${product_id}/offers`
    }
}


class ProductNomenclatureService extends BaseService {
    MODEL = 'product-nomenclature'
}


export {
    ProductService,
    ProductNomenclatureService,
    ProductOfferService
}
