import BaseService from './BaseAPI'


class MeasureService extends BaseService{
  MODEL='measure'

}

export {
    MeasureService,
}
