import {useState} from "react";


export default function usePagination() {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);

    const previousPage = () => {
        if (page > 1) {
            setPage(prev => prev--);
        }
    }

    const nextPage = () => {
        setPage(prev => prev++)
    }

    const actions = {
        setPage,
        setLimit,
        previousPage,
        nextPage

    }
    return [page, limit, actions]
}
