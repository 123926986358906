import React, {useEffect, useState} from "react";
import {Select, Space, Typography} from "antd";
import Currency from "../../Components/Accounts/utils/Currency";
import {TagService} from "../../API/TagsAPI";
import {AccountService} from "../../API/AccountAPI";
import {ContragentService} from "../../API/ContragentAPI";
import {get_id_ifnot} from "../../lib/tools";


export default function AccountSelect({accounts, OptionKey, showTags = true, ...props}) {

    const [tags, setTags] = useState({});
    // const [tags_acc, setTagsAcc] = useState({})
    // const [t, setT] = useState();

    useEffect(() => {
        if (showTags) {
            onSelect();
        }
    }, []);

    function onSelect(a, b) {
        if (!tags) {
            let t = new TagService();
            accounts.map(a => {
                // t.getUsedTags(a.id).then(({data:{data}}) => {
                //   setTags({...{[a.number]: data}, ...(tags || {})});
                // });
                let resp = t.getUsedTags(a.id);
                setTags({...{[a.number]: resp.data.data}, ...(tags || {})});
                return a;
            });
        }
        // if (a && b) {
        //   setT(tags[a]);
        // }
    }

    return <Select
        notFoundContent={"Счетов не найдено"}
        //onChange={onSelect}
        {...props}
    >
        {accounts.map((el) => (
            <Select.Option value={OptionKey ? el[OptionKey] : el.id} key={OptionKey ? el[OptionKey] : el.id}>
                <Space style={el.type === "reserve" && {marginLeft: 20}}>
                    <Currency code={el.currency} type="flag" style={{
                        width: "15px",
                        borderRadius: "50%",
                        marginTop: "7px",
                    }}/>
                    <Typography.Text style={{margin: 0}}
                                     copyable={{tooltips: ["скопировать номер счёта", "скопировано"]}}>
                        {el.number}
                    </Typography.Text>
                    <Typography.Text style={{margin: 0}}>
                        {el.name}
                    </Typography.Text>
                    {/*<Tags tags={t}/>*/}
                    {/*{tags[el.number] && tags[el.number]?.map?.(t=><Tag color={t.color}>{t.name}</Tag>)}*/}
                </Space>
                {/* <Typography.Text>Валюта: {el.currency}</Typography.Text> */}
            </Select.Option>
        ))}
    </Select>;
}


export function AccountDataSelect({value, onChange, contragent, optionKey, showTags = true, ...props}) {
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        if (contragent) {
            new ContragentService(get_id_ifnot(contragent)).accounts().then(r => {
                setAccounts(r.data.data.filter(el => el.type === "internal"));
            });
        } else {
            new AccountService().list().then(r => {
                setAccounts(r.data.data.filter(el => el.type === "internal"));
            });
        }
    }, [contragent]);

    function _onChange(val) {
        onChange(val);
    }

    return <AccountSelect value={value}
                          accounts={accounts}
                          onChange={_onChange}
                          OptionKey={optionKey}
                          showTags={showTags}
                          {...props}/>;
}

