import React from "react";
import {Modal, Descriptions, Form, Button, InputNumber} from "antd";
import {OrderService} from "src/API/OrderAPI";
import {useOrder} from "src/Providers/OrderProvider";
import Money from "../../Accounts/utils/Money";
import Currency from "../../Accounts/utils/Currency";


export function PartPayOrderModal({item, ...props}) {

    function partPaymentOrder(data) {
        new OrderService(item.id).pay(data).then(response => {

        });
    }

    return (
        <Modal title="Частичная оплата"
               visible={props.partPayModalOpen}
               onOk={() => partPaymentOrder()}
               onCancel={() => props.setPartPayModalOpen(false)}
               footer={
                   <Button type="primary" htmlType="submit" form="part_payment_form">Оплатить</Button>
               }
               {...props}
        >
            <Form id="part_payment_form" onFinish={partPaymentOrder}>
                <Form.Item>
                    <Descriptions column={1} bordered size="small">
                        <Descriptions.Item label="Сумма заказа">{item.price}</Descriptions.Item>
                        <Descriptions.Item label="Уже оплачено">{item.paid_amount}</Descriptions.Item>
                    </Descriptions>
                </Form.Item>
                <Form.Item name="amount" rules={[{required: true, message: "Необходимо указать корректную сумму"}]}>
                    <InputNumber style={{width: "100%"}} min={0} max={item.price}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}


export function PartPayOrderItemModal({item, onSuccess, ...props}) {

    const {order} = useOrder();

    function partPaymentOrder(data) {
        data["order_items"] = [item.id];
        new OrderService(order.id).pay(data).then(response => {
            onSuccess?.(response.data);
        });
    }

    return (
        <Modal title={`Частичная оплата позиции № ${item?.id}`}
               footer={
                   <Button type="primary" htmlType="submit" form="part_payment_form">Оплатить</Button>
               }
               {...props}
        >
            <Form id="part_payment_form" onFinish={partPaymentOrder}>
                <Form.Item>
                    <Descriptions column={1} bordered size="small" contentStyle={{textAlign: "right"}}>
                        <Descriptions.Item label="Наименование">{item?.product_name}</Descriptions.Item>
                        <Descriptions.Item label="Сумма позиции" contentStyle={{textAlign: "right"}}>
                            <Money sum={item?.cost} currency={order.currency}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Оплачено" contentStyle={{textAlign: "right"}}>
                            <Money sum={item?.paid} currency={order.currency}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Количество" contentStyle={{textAlign: "right"}}>
                            {item?.count} {item?.measure ? item?.measure : ""}
                        </Descriptions.Item>
                    </Descriptions>
                </Form.Item>
                <Form.Item name="amount" rules={[{required: true, message: "Необходимо указать корректную сумму"}]}>
                    <InputNumber prefix={<Currency code={order.currency}/>} style={{width: "100%"}} min={0}
                                 max={item?.cost - item?.paid}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}
