import React from 'react'
import {Modal} from 'antd'

import CrossgradeTable from '../Tables/CrossgradeTable';


export default function SubscribeHistoryModal({service_item, ...props}) {
    // модальное окно по информации кроссгрейдов

    return (
        <Modal {...props} width='90%' destroyOnClose title='История параметров подписки' footer={false}>
            <CrossgradeTable service_item={service_item}/>
        </Modal>
    )
}
