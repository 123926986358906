import React, {useEffect} from "react";
import {Button, Form, Modal, notification, message} from 'antd'

import NomenclatureForm from "../Forms/NomenclatureForm";
import {ProductNomenclatureService} from '../../../API/ProductAPI';


export default function NomenclatureModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                uuid: data.uuid,
                name: data.name,
                code: data.code,
                description: data.description,
                pp_product: data.pp_product
            });
        } else
            form.resetFields();
    }, [data])

    function editAttribute(d) {
        if (d.code === data.code)
            delete d.code

        d['contragent'] = props.contragent?.external_id || props.contragent?.id

        new ProductNomenclatureService(data.uuid).update(d).then(res => {
            message.success('Номенклатура изменена');
            props.toggle()

        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить номенклатуру",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function createAttribute(d) {
        d['contragent'] = props.contragent?.external_id || props.contragent?.id
        new ProductNomenclatureService().create(d).then(res => {
            message.success('Номенклатура создана');
            props.toggle()
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить номенклатуру",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function execute(d) {
        if (data) {
            editAttribute(d);
        } else {
            createAttribute(d);
        }
        onSuccess();
    }

    return <Modal {...props} onSuccess={onSuccess} onOk={onSuccess} footer={
        <Button type="primary" htmlType="submit" form="manage_nomenclature">Сохранить</Button>
    }>
        <NomenclatureForm form={form} id='manage_nomenclature' onFinish={(d) => execute(d)}/>
    </Modal>
}
