import React, {useEffect} from "react";
import {Button, Form, message, Modal} from 'antd'
import {DictionaryService} from "../../../API/DictionaryAPI";
import DictionaryForm from "../Forms/DictionaryForm";


export default function DictionaryModal({data, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            new DictionaryService(data['id']).get().then(res => {
                res.data.attributes = res.data.attributes.map(el => {
                    el = el.id
                    return el
                })
                form.setFieldsValue(res.data);
            })
        } else {
            form.resetFields();
        }
    }, [data, form])

    function editAttribute(d) {
        new DictionaryService(data['id']).update(d).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }
        }).catch(err => {
            message.error('Ошибка')
        })
    }

    function createAttribute(d) {
        new DictionaryService().create(d).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }
        }).catch(err => {
            message.error('Ошибка')
        })
    }

    function execute(d) {
        if (data) {
            editAttribute(d);
        } else {
            createAttribute(d);
        }
    }

    return <Modal {...props} footer={
        <Button type="primary" htmlType="submit" form="manage_attribute">Сохранить</Button>
    }>
        <DictionaryForm form={form} id='manage_attribute' onFinish={d => execute(d)}/>
    </Modal>
}
