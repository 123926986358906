import debounce from "lodash/debounce";
import React, {useMemo, useRef, useState, useEffect} from "react";

import {DatePicker, Input, InputNumber, Radio, Select, Spin} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

//https://4x.ant.design/components/select/
export function DebounceSelect({fetchOptions, debounceTimeout = 800, ...props}) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    // для инициализации
    useEffect(() => {
        debounceFetcher();
    }, []);

    return (
        <Select
            //   labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small"/> : null}
            {...props}
            options={options}
        />
    );
}

// Usage of DebounceSelect

// async function fetchUserList(username) {
//   console.log('fetching user', username);
//   return fetch('https://randomuser.me/api/?results=5')
//     .then((response) => response.json())
//     .then((body) =>
//       body.results.map((user) => ({
//         label: `${user.name.first} ${user.name.last}`,
//         value: user.login.username,
//       })),
//     );
// }
// const App = () => {
//   const [value, setValue] = useState([]);
//   return (
//     <DebounceSelect
//       mode="multiple"
//       value={value}
//       placeholder="Select users"
//       fetchOptions={fetchUserList}
//       onChange={(newValue) => {
//         setValue(newValue);
//       }}
//       style={{
//         width: '100%',
//       }}
//     />
//   );
// };
// export default App;


const COMPONENT_TYPES = {
    "int": InputNumber,
    "float": InputNumber,
    "str": Input,
    "datetime": DatePicker,
    "date": DatePicker,
    "bool": Checkbox,
    "enum": Select,
    "enum_multiple": Select,
};

function renderInput(type, options) {
    let Component = COMPONENT_TYPES[type] || Input;
    if (type === "enum_multiple") {
        return <Select showSearch mode={"multiple"}>
            {(options || []).map(x => (
                <Select.Option key={x.id} value={x.id}>{x.value}</Select.Option>
            ))}
        </Select>;
    } else if (type === "bool") {
        return <Radio.Group>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
        </Radio.Group>;
    } else {
        return <Component showSearch
                          options={(options || []).map(x => {
                              if (!x.key) x.key = x.id;
                              return x;
                          })}/>;
    }
}

export {
    COMPONENT_TYPES,
    renderInput,
};


