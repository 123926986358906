import React, {useState} from "react";
import {Input, Space, Table, Button, Divider, Col, Row} from "antd";
import {ReloadOutlined} from '@ant-design/icons'
import {SearchOutlined} from "@ant-design/icons";
import {useDebounce} from "react-use";
import DataTable from "app/Base/Tables/DataTable";


function DebouncedInput({value, onChange, placeholder, ...props}) {
    const [_val, setVal] = useState(value);

    useDebounce(() => onChange(_val), 1000, [_val]);

    return <Input style={{maxWidth: "100%"}}
                  prefix={<SearchOutlined/>}
                  placeholder={placeholder}
                  allowClear
                  value={_val}
                  onChange={(e) => setVal(e.target.value)}/>;
}

export default function SearchTable({dataSource, title, onSearchEdit, toggle, ...props}) {

    return <Table className={'stable'}
                  showHeader={true}
                  dataSource={dataSource}
                  title={() => (
                      <Space>
                          {title?.()}
                          <DebouncedInput onChange={x => onSearchEdit && onSearchEdit(x)}
                                          placeholder={'Поиск'}/>
                          <Button icon={<ReloadOutlined/>} onClick={() => toggle?.()}/>
                      </Space>)}
                  {...props}/>
}


export function SearchDataTable(
    {
        model, columns, title,
        onSearchEdit, toggle, additional_title,
        extra,
        ...props
    }) {

    const {setSearch, setPage} = model;

    function onChange(x) {
        setSearch(x);
        if (x?.length)
            setPage(1);
    }

    return <DataTable model={model}
                      columns={columns}
                      title={() => (
                          <Space>
                              {title?.()}
                              <DebouncedInput onChange={onChange}
                                              placeholder={'Поиск'}/>
                              <Button icon={<ReloadOutlined/>} onClick={() => toggle?.()}/>
                              <Divider type={'vertical'}/>
                              {typeof additional_title == 'function' &&
                                  additional_title() ||
                                  additional_title}
                              {additional_title &&
                                  <Divider type={'vertical'}/>}
                              {typeof extra == 'function' &&
                                  extra() ||
                                  extra}
                          </Space>
                      )}
                      {...props}/>;

}
