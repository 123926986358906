import React from "react";
import PropTypes from "prop-types";
import {Button, Modal as ModalANTD, Space} from "antd";


export default function Modal({
                                  children,
                                  onSuccess,
                                  onCancel,
                                  form,
                                  title,
                                  btnname,
                                  visible,
                                  footer,
                                  hideCancelBtn = false,
                                  destroyOnClose = true,
                                  maskClosable = false,
                                  ...props
                              }) {

    return <ModalANTD destroyOnClose={destroyOnClose}
                      visible={visible || false}
                      title={title}
                      onCancel={onCancel}
                      onOk={onSuccess}
                      maskClosable={maskClosable}
                      footer={(footer === false) ?
                          null : (
                              footer ||
                              <Space>
                                  <Button type="primary"
                                          htmlType="submit"
                                          form={form}
                                          onClick={onSuccess}>
                                      {btnname || "ОК"}
                                  </Button>
                                  {!hideCancelBtn &&
                                      <Button htmlType="cancel"
                                              onClick={() => (typeof onCancel === "function" && onCancel(false))}
                                      >Отмена</Button>}
                              </Space>)}
                      {...props}
    >{children}</ModalANTD>;
}


Modal.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.string,
    title: PropTypes.string.isRequired,
    btnname: PropTypes.string,
    visible: PropTypes.bool.isRequired,
};
