import {message, notification} from 'antd'
import axios from '../config/axios'
import BaseService from './BaseAPI'

class PaymentService extends BaseService {
    MODEL = 'modules/modules.etp_gpb/payment'

    getPayments({params = new URLSearchParams()} = {}) {
        return this._get('list', {params: params});
    }

    createPayment(data) {
        return axios.post(this._mk_res('create'), data).then(res => {
            return Promise.resolve(res)
        }).catch(err => {
            if (err.response.data && err.response.data.detail) {
                notification.error({
                    message: "Невозможно создать платёж",
                    description: err.response.data.detail
                })
            }
            return Promise.reject(err)
        })
    }

    correctPayment(data) {
        return axios.post(this._mk_res('correct'), data)
    }

    cancelPayment(data) {
        return axios.post(this._mk_res('cancel'), data)
    }

}


class DerivationService extends BaseService {
    MODEL = 'modules/modules.etp_gpb/derivation'

    list({params}) {
        return axios.get(this._mk_res('list'), {params: params});
    }

    create(data) {
        return this._post('create', data).then(res => Promise.resolve(res)).catch(err => {
            notification.error({message: "Невозможно оформить заявку",
                                description: err?.response?.data?.detail ||
                                             err?.response?.data?.Message});
            return Promise.reject(err);
        })
    }

    changeState(data) {
        return this._post('changestate', data);
    }

}


class ReconService extends BaseService {
    MODEL = 'modules/modules.etp_gpb/recon'


    listRecon({params}) {
        return axios.get(this._mk_res('list'), {params: params})
    }

    createRecon(data) {
        return axios.post(this._mk_res('create'), data)
    }

    changeRecon(data) {
        return axios.post(this._mk_res('changestate'), data)
    }

}

class Axios {
    constructor(error = 'error', success = 'success') {
        this.error = error
        this.success = success
    }

    catch(error) {
        if (this.error) {
            message.error(this.error)
        } else if (typeof error.response.data === 'object') {
            for (const k in error.response.data) {
                message.error(error.response.data[k])
            }
        }
    }

    create(id, values) {
        axios.post(`/api/${this.url}/${id}`, values).then(() => {
            message.success(this.success)
        }).catch((error) => {
            this.catch(error)
        })
    }

    getRetrieveURL(id) {
        return `/api/${this.url}/${id}`
    }

    list(then) {
        axios.get(`/api/${this.url}`).then((response) => {
            then(response)
        }).catch((error) => {
            // this.catch(error)
        })
    }

    retrieve(id, then) {
        axios.get(`/api/${this.url}/${id}`).then((response) => {
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

    forms(id, then) {
        axios.get(`/api/${this.url}/${id}/forms`).then((response) => {
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

    detail_query(id, action, method, data, content_type = 'application/json', then) {
        let query = axios.get

        if (method === 'POST') {
            query = axios.post
        } else if (method === 'GET') {
            query = axios.get
        } else if (method === 'PATCH') {
            query = axios.get
        } else if (method === 'DELETE') {
            query = axios.get
        }

        query(`/api/${this.url}/${id}/${action}`, data, {
            headers: {'Content-Type': content_type}
        }).then((response) => {
            if (response.data.msg) {
                message.success(response.data.msg)
            }
            then(response)
        }).catch((error) => {
            this.catch(error)
        })
    }

}

export default class API extends Axios {
    constructor(...args) {
        super(...args)
        this.url = 'modules'
    }
}

export {
    ReconService,
    PaymentService,
    DerivationService
}
