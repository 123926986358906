import React, {useEffect, useState} from "react";
import {useEffectOnce, useToggle} from "react-use";
import PropTypes from "prop-types";
import {Checkbox, Col, Descriptions, Form, Input, InputNumber, Row, Select, Space} from "antd";
import {TransactionService} from "../../../API/TransactionAPI";
import useAccount from "../../../Providers/AccountProvider";


// function compute({amount, procent, isTax, taxUp}){
//   let tax = 0;
//   let total = isNaN(amount) ? 0 : +amount;

//   if(!isTax)
//     return {amount: total, tax: tax, total: amount}

//   if(isTax && taxUp){
//     amount = total
//     tax    = Math.round((total - amount) * 100) / 100;
//     total  = amount + tax
//     return {amount, tax, total}
//   }
//   amount = Math.round((total / (1 + +procent / 100)) * 100) / 100;
//   tax    = Math.round((total - amount) * 100) / 100;
//   return {amount, tax, total}
// }

export default function DebitForm({onFinish, ...props}) {

    const [form] = Form.useForm();
    const {account, toggle} = useAccount();

    const [taxDisabled, toggleTaxDisabled] = useToggle(true);
    const [taxIsUp, taxIsUpToggle] = useState(false);

    const [stageVisible, setStageVisible] = useState(false);

    const [payType, setPayType] = useState([]);

    const [info, setInfo] = useState({
        amount: 0,
        tax: 0,
        taxCalculated: 0,
        total: 0,
    });

    useEffectOnce(() => {
        new TransactionService().payTypes().then((res) => {
            let pay_type_list = res.data.data.filter(el => {
                return el.type === 4
            })
            setPayType(pay_type_list);
        });
        form.setFields([{name: "procent", value: 20}]);
        setStageVisible(true)
    });

    useEffect(() => {
        computeInfoChange();
    }, [taxDisabled, taxIsUp]);

    let negativeAmountValidator = (rule, value) => {
        return +value < 0 ? Promise.reject() : Promise.resolve();
    }

    let zeroAmountValidator = (rule, value) => {
        return +value === 0 ? Promise.reject() : Promise.resolve();
    }

    function computeInfoChange() {
        // Меняет общую информацию
        let amount, taxProcent, taxCalculated, total;

        if (taxIsUp) {
            // НДС сверху
            amount = isNaN(+form.getFieldValue("amount"))
              ? 0
              : +form.getFieldValue("amount");
            taxProcent = !taxDisabled ? +form.getFieldValue("procent") : 0;
            taxCalculated = Math.round(amount * taxProcent) / 100;
            total = amount + taxCalculated;
        } else {
            // НДС внутри
            total = isNaN(+form.getFieldValue("amount"))
              ? 0
              : +form.getFieldValue("amount");
            taxProcent = !taxDisabled ? +form.getFieldValue("procent") : 0;
            amount = Math.round((total / (1 + taxProcent / 100)) * 100) / 100;
            taxCalculated = Math.round((total - amount) * 100) / 100;
        }
        setInfo({
            amount: amount,
            tax: taxProcent,
            taxCalculated: taxCalculated,
            total: total,
        });
    }


    function onSendData(data) {
        data = {
            type: 4,
            paytype: data.paytype,
            number: data.number,
            amount: info.amount,
            from_number: account?.number,
            procent: info.tax,
            tax: info.taxCalculated,
            total: info.total,
            comment: data.comment,
        }
        new TransactionService().debit(data).then(res => {
            typeof onFinish === 'function' && onFinish(res.data);
        }).finally(() => {
            toggle();
        })
    }

    return (
      <Form {...props} layout="vertical" form={form} onFinish={onSendData}>
          <Form.Item
            name="paytype"
            label="тип списания"
            rules={[{required: true, message: "поле обязательно к заполнению"}]}
          >
              <Select>
                  {payType.map((type) => {
                      return <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>;
                  })}
              </Select>
          </Form.Item>

          {!account?.number ? (
            <Form.Item
              name="number"
              label="счет списания"
              rules={[
                  {required: true, message: "необходимо выбрать счёт пополнения"},
              ]}
            >
            </Form.Item>
          ) : (
            <Descriptions bordered size="small" style={{marginBottom: "1em"}}>
                <Descriptions.Item label="Счёт списания">
                    {account?.number}
                </Descriptions.Item>
            </Descriptions>
          )}

          {stageVisible && (
            <>
                <Row gutter={16}>
                    <Col xs={12}>
                        <Form.Item
                          name="amount"
                          label="сумма"
                          required
                          rules={[
                              {required: true, message: "должна быть указана сумма"},
                              {
                                  validator: negativeAmountValidator,
                                  message: "сумма не может быть отрицательной",
                              },
                              {
                                  validator: zeroAmountValidator,
                                  message: "сумма не может быть равной нулю",
                              },
                          ]}
                        >
                            <InputNumber
                              onChange={computeInfoChange}
                              style={{width: "100%"}}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                          name="procent"
                          label={
                              <>
                                  <Space>
                                      <Checkbox
                                        onChange={(val) => {
                                            toggleTaxDisabled();
                                        }}
                                      />
                                      НДС
                                      <Checkbox
                                        onChange={(e) => {
                                            taxIsUpToggle(!taxIsUp);
                                        }}
                                        checked={taxIsUp}
                                      />
                                      Сверху
                                  </Space>
                              </>
                          }
                        >
                            <InputNumber
                              disabled={taxDisabled}
                              onChange={computeInfoChange}
                              value={20}
                              min={0}
                              max={100}
                              formatter={(value) => `${value}%`}
                              style={{width: "100%"}}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="comment" label="комментарий">
                    <Input.TextArea/>
                </Form.Item>

                <Descriptions
                  title="Предварительный расчёт"
                  size="small"
                  bordered
                  column={2}
                >
                    <Descriptions.Item label="сумма" span={2}>
                        {info.amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="НДС">{info.tax}%</Descriptions.Item>
                    <Descriptions.Item label="сумма">
                        {info.taxCalculated}
                    </Descriptions.Item>
                    <Descriptions.Item label="Итого">{info.total}</Descriptions.Item>
                </Descriptions>
            </>
          )}
      </Form>
    );
}

DebitForm.propTypes = {
    number: PropTypes.string,
    onFinish: PropTypes.func.isRequired,
};
