import React, {useState} from "react";
import {Button, Form, message, Space, Table} from 'antd';

import useUser from "../../../Providers/UserProvider";
import UserService from "../../../API/UserAPI";
import useAuth from "../../../Providers/AuthProvider";
import {useEffectOnce} from "react-use";


export default function CtgSelectForm({hideButtons=false, ...props}) {

    const {logout} = useAuth();
    const {user, changeContragent, showContragentSelect} = useUser();
    const [contragents, setContragents] = useState([]);
    const [ctg, setCtg] = useState();

    const columns = [
        {title: 'Контрагент', dataIndex: 'name', key: 'name'},
        // {title: 'Тип', dataIndex: 'type', key: 'type'},
    ]

    useEffectOnce(() => {
        new UserService(user?.id).getContragents().then(
            res => {
                let {data, count} = res.data;

                setContragents(data)
                if (count === 1) {
                    let ctg = data[0];
                    changeContragent(ctg.id)
                }
            }
        )
    })

    return (
        <Form id='ctg_select_form' onFinish={() => {
            if (ctg) {
                changeContragent(ctg.id);
                showContragentSelect(false);
            } else {
                message.error(`Необходимо выбрать контрагента!`)
            }
        }} {...props}>

            <Form.Item name='contragent_id'>
                {contragents.length > 0 ?
                    <Table columns={columns}
                           dataSource={contragents}
                           pagination={false}
                           rowKey={'id'}
                           defaultSelectedRowKeys={1}
                           rowSelection={
                               {
                                   type: 'radio',
                                   onChange: (key, row) => {
                                       setCtg(row[0])
                                   },
                               }
                           }
                    /> : <>У пользователя нет контрагентов</>}
            </Form.Item>

            {!hideButtons &&
                <Space>
                    {(contragents.length > 0) &&
                    <Button type='primary'
                            htmlType='submit'>Войти</Button>}
                    <Button onClick={logout}>Выйти</Button>
                </Space>}

        </Form>
    )
}
