import React, {useEffect} from "react";
import {Button, Form, Modal, notification} from 'antd'
import AttributeForm from "../Forms/AttributeForm";
import {AttributeService} from "../../../API/DictionaryAPI";


export default function AttributeModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                id: data.id,
                name: data.name,
                measure: data.measure?.id || data.measure,
                codename: data.codename,
                type: data.type,
                enum_group: data.enum_group,
                is_required: data.is_required || false
            });
        } else
            form.resetFields();
    }, [data])

    function editAttribute(d) {
        new AttributeService(data.id).update(d).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить атрибут",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function createAttribute(d) {
        new AttributeService().create(d).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить атрибут",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function execute(d) {
        if (data) {
            editAttribute(d);
        } else {
            createAttribute(d);
        }
        onSuccess(d);
    }

    return <Modal {...props} onSuccess={onSuccess} onOk={onSuccess} footer={
        <Button type="primary" htmlType="submit" form="manage_attribute">Сохранить</Button>
    }>
        <AttributeForm form={form} id='manage_attribute' onFinish={(d) => execute(d)}/>
    </Modal>
}
