import {AttributableService} from "./AttributableService";

class PackageService extends AttributableService {
    MODEL = 'package'

    calcProlongDate(params) {
        return this._get('calc_prolong_date', {params: params})
    }

    getStatuses() {
        return this._get('statuses')
    }

    getContragents() {
        return this._get('contragents')
    }

    updateContragents(data) {
        return this._patch('contragents', data)
    }

    getFreeConts() {
        return this._get('free-conts')
    }

    getPrices() {
        return this._get(`offers`)
    }

    patchAttributesValue(data) {
        try {
            this._check_id()
            return this._patch(`attributes`, data)
        } catch (err) {
            return this._patch('attributes', data)
        }
    }

    getTaxes() {
        return this._get(`taxes`)
    }

    getAttributesValue() {
        return this._get("attributes")
    }

    deletePrice(id) {
        this._check_id()
        return this._delete(`offers/${id}`)
    }

    patchPrice(price_id, data) {
        return this._patch(`offers/${price_id}`, data)
    }

    createPrice(data) {
        return this._post(`offers`, data)
    }

    createOrUpdatePrice(price_id, data) {
        if (price_id)
            return this.patchPrice(price_id, data)
        else
            return this.createPrice(data)
    }
}


export {
    PackageService,
}
