import {Tag} from "antd";
import React from "react";

const orderStatusTag = (el, complete = 'актирован') => {
    switch (el) {
        case 'new':
            return <Tag color='green'>новый</Tag>
        case 'processing':
            return <Tag color='blue'>в процессе</Tag>
        case 'cancel':
            return <Tag color='red'>отменён</Tag>
        case 'draft':
            return <Tag>черновик</Tag>
        case 'complete':
            return <Tag color='yellow'>{complete}</Tag>
        default:
            return <Tag color='volcano'>не определён</Tag>
    }
}

const OrderTypeTag = (el) => {
    if (typeof el === 'object') {
        el = el.el
    }
    switch (el) {
        case 'crossgrade_sub':
            return <Tag color='blue'>Изменение подписки</Tag>
        case 'crossgrade_sub_package':
            return <Tag color='blue'>Изменение пакета</Tag>
        case 'freeze_order':
            return <Tag color='red'>Заказ на заморозку</Tag>
        case 'withdrawal_order':
            return <Tag color='blue'>Заказ на вывод средств</Tag>
        case 'primary_order':
            return <Tag color='green'>Первичный</Tag>
        case 'renewal_order':
            return <Tag color='yellow'>Продление</Tag>
        case 'partner':
            return <Tag color='blue'>Партнёр</Tag>
        case 'refill':
            return <Tag color='grey'>Пополнение</Tag>
        default:
            return <Tag color='green'>Первичный</Tag>
    }
}

let OrderPayStatusTag = (el) => {
    switch (el) {
        case 'paid':
            return <Tag color='green'>оплачен</Tag>
        case 'cancel':
            return <Tag color='red'>отменён</Tag>
        case 'not_paid':
            return <Tag color='red'>не оплачен</Tag>
        case 'not_need':
            return <Tag>не требует</Tag>
        case 'underpaid':
            return <Tag color='orange'>частичная оплата</Tag>
        case 'overpaid':
            return <Tag color='purple'>переплата</Tag>
        default:
            return <Tag color='red'>не определён</Tag>
    }
}

const ContractStatusTag = (el) => {
    switch (el) {
        case 0:
            return <Tag color='yellow'>запрошен договор</Tag>
        case 1:
            return <Tag color='green'>активный</Tag>
        case 2:
            return <Tag color='red'>отменён</Tag>
        case 3:
            return <Tag color='red'>расторгнут</Tag>
        case 4:
            return <Tag color='grey'>в архиве</Tag>
        case 5:
            return <Tag color='blue'>подписан</Tag>
        case 6:
            return <Tag color='grey'>заморожен</Tag>
        case 7:
            return <Tag color='grey'>приостановлен</Tag>
        case 8:
            return <Tag color='yellow'>на согласовании</Tag>
        case 9:
            return <Tag color='red'>заблокирован</Tag>
        case 10:
            return <Tag color='yellow'>на расторжении</Tag>
        default:
            return <Tag>не определён</Tag>
    }
}

const productStatusTag = (el) => {
    switch (el) {
        case 1:
            return <Tag color='green'>Активный</Tag>
        case 2:
            return <Tag color='grey'>В архиве</Tag>
        case 3:
            return <Tag color='yellow'>Черновик</Tag>
    }
}


const InvoicePaidStatusTag = (el) => {
    switch (el) {
        case 1:
            return <Tag color='green'>Активный</Tag>
        case 2:
            return <Tag color='grey'>В архиве</Tag>
        case 3:
            return <Tag color='yellow'>Черновик</Tag>
    }
}

const CrossgradeStatusTag = (el) => {
    switch (+el) {
        case 1:
            return <Tag color='green'>Активный</Tag>
        case 2:
            return <Tag color='grey'>Запланирован</Tag>
        case 3:
            return <Tag color='blue'>Завершен</Tag>
        case 4:
            return <Tag color='grey'>Приостановлен</Tag>
        case 5:
            return <Tag color='red'>Отменён</Tag>
        case 6:
            return <Tag color='grey'>Не продлён</Tag>
        default:
            return <Tag>не определён</Tag>
    }
}

const OrderRepeatedStatus = (el) => {
    switch (el) {
        case null:
            return <Tag color='green'>Первичный</Tag>
        default:
            return <Tag color='yellow'>Повторный</Tag>
    }
}

export {
    orderStatusTag,
    OrderPayStatusTag,
    ContractStatusTag,
    OrderRepeatedStatus,
    CrossgradeStatusTag,
    productStatusTag,
    OrderTypeTag
}
