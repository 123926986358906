import React, {useEffect} from "react";
import {Button, Form, Modal, notification, Select} from 'antd'
import {EnumGroupService} from "../../../API/DictionaryAPI";
import EnumGroupForm from "../Forms/EnumGroupForm";

// const Option = Select.Option;

export default function EnumGroupModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                name: data.name,
                values: data.values
            });
        } else {
            form.resetFields();
        }
    }, [data])

    function edit(d) {
        const egs = new EnumGroupService(data.id);
        console.log(d)
        egs.replace(d).then(res => {
            typeof onSuccess==='function' && onSuccess(res.data);
        }).catch(EnumGroupService.create_error_handler("Невозможно сохранить список"));
    }

    function create(d) {
        new EnumGroupService().create(d).then(res => {
            typeof onSuccess==='function' && onSuccess(res.data);
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить список",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function execute(d) {
        if (data) {
            edit(d);
        } else {
            create(d);
        }
    }

    return <Modal {...props} onSuccess={onSuccess} onOk={onSuccess} footer={
        <Button type="primary" htmlType="submit" form="enumgroup">Сохранить</Button>
    }>
        <EnumGroupForm form={form} id='enumgroup' onFinish={execute}/>
    </Modal>
}
