import {Layout} from "antd";
import {AccountProvider} from "app/Providers/AccountProvider";
import React from "react";
import {Route, Switch} from "react-router";
import {CartProvider} from "src/Providers/CartProvider";
import CartDetail from "../Products/Bucket";
import Product from "../Products/Products";
import ClientTable from "./ClientTable";
import {ContragentProvider} from "./ContragentContext";
import ContragentDetail from "./ContragentDetail";


export default function Client(){

    // const {is_permitted} = useUser();

    return <Layout.Content>
        <Switch>
            <Route path='/contragents/:id/products'>
                <Product />
            </Route>
            <Route path='/contragents/:id/cart'>
                <ContragentProvider>
                    <CartProvider>
                        <CartDetail />
                    </CartProvider>
                    {/* <CartDetail /> */}
                </ContragentProvider>
            </Route>
            <Route path='/contragents/:id'>
                <ContragentProvider>
                    <AccountProvider>
                        <ContragentDetail />
                    </AccountProvider>
                </ContragentProvider>
            </Route>
            <Route exact path='/contragents'>
                <ClientTable />
            </Route>
        </Switch>
    </Layout.Content>
}
