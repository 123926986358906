import React from "react";
import PropTypes from 'prop-types';
import {Button, message, Modal} from "antd";
import TransferForm from '../../../Base/Forms/TransferForm';
import useAccount from "../../../Providers/AccountProvider";
import {TransactionService} from "../../../API/TransactionAPI";


export default function TransferFormModal({onSuccess, ...props}) {
    const {account, toggle} = useAccount();

    function onTransaction(data) {
        if (account)
            data['from_number'] = account?.number
        data['type'] = 2
        new TransactionService().create(data).then(res => {
            message.success('транзакция успешна');
            if (typeof onSuccess === 'function') {
                onSuccess(res.data);
            }
            toggle();
        }).catch(err => {
            message.error(`Ошибка транзакции ${err.response.data.detail}`);
        })
    }

    return <Modal destroyOnClose {...props}
                  title='Перевести на ЛС'
                  footer={
                      <Button
                        type='primary'
                        htmlType='submit'
                        form='transfer_form'
                      >
                          Отправить
                      </Button>
                  }
    >
        <TransferForm
          id='transfer_form'
          from_number={account?.number}
          onFinish={onTransaction}
        />
    </Modal>
}


TransferFormModal.propTypes = {
    number: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    contragent: PropTypes.object
}
