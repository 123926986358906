import React, {useState} from 'react';
import {Modal, Menu} from 'antd'
import { MarketTable } from '../../Marketplace/Table/MarketTable';
import {PackageTable} from '../../Products/PackageTable';

export function OfferModal({...props}) {
  const [option, setOption] = useState('offer')

  return (
    <Modal {...props} width={'80%'} destroyOnClose={true} footer={false}>
        <Menu mode="horizontal">
            <Menu.Item key='offers' onClick={() => setOption('offer')}>Предложения</Menu.Item>
            <Menu.Item key='packages' onClick={() => setOption('packages')}>Пакетные предложения</Menu.Item>
        </Menu>
        {option === 'offer' && <MarketTable />}
        {option === 'packages' && <PackageTable />}
    </Modal>
  )
}

