import React, {useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Checkbox, Col, Descriptions, Form, Input, InputNumber, Row, Select, Space, Typography} from 'antd'
import {AccountService} from "../../API/AccountAPI";
import {useDebounce, useEffectOnce, useToggle} from "react-use";
import {TransactionService} from "../../API/TransactionAPI";


const DebouncedInputNumber = ({value, onChange, ...props}) => {
    const [val, setVal] = useState(value);

    useDebounce(() => {
        if (val) onChange(val);
    }, 250, [val]);

    return <InputNumber onChange={setVal} {...props}/>;
}


export default function TransferForm({from_number, to_number, onFinish, ...props}){
    //Отрефакторить форму переводов

    const [form] = Form.useForm();

    const [taxDisabled, toggleTaxDisabled] = useToggle(true);
    const [taxIsUp, taxIsUpToggle] = useState(false);

    const [stageVisible, setStageVisible] = useState(false);

    const [fromAccounts, setFromAccounts] = useState([]);
    const [toAccounts, setToAccounts] = useState([]);
    const [payType, setPayType] = useState([]);

    const [info, setInfo] = useState({
        amount: 0,
        tax: 0,
        taxCalculated: 0,
        total: 0
    })

    const computeInfoChange = useCallback(() => {
        let amount, taxProcent, taxCalculated, total;
        if (taxIsUp) { // НДС сверху
            amount = isNaN(+form.getFieldValue('amount')) ? 0 : +form.getFieldValue('amount');
            taxProcent = !taxDisabled ? +form.getFieldValue('procent') : 0;
            taxCalculated = Math.round(amount * taxProcent) / 100;
            total = amount + taxCalculated;
        } else { // НДС внутри
            total = isNaN(+form.getFieldValue('amount')) ? 0 : +form.getFieldValue('amount');
            taxProcent = !taxDisabled ? +form.getFieldValue('procent') : 0;
            amount = Math.round(total/(1 + taxProcent/100)*100)/100
            taxCalculated = Math.round((total - amount)*100)/100;
        }
        setInfo({amount: amount, tax: taxProcent, taxCalculated: taxCalculated, total: total})

    }, [taxDisabled, taxIsUp, form])

    useEffectOnce(() => {
        new TransactionService().payTypes().then(res => {
            setPayType(res.data.data)
        })
        form.setFields([{name: 'procent', value: 20}])
    })

    useEffect(() => {
        computeInfoChange()
    }, [taxDisabled, taxIsUp, computeInfoChange])


    function negativeAmountValidator(rule, value){
        return +value < 0 ? Promise.reject() : Promise.resolve()
    }

    function zeroAmountValidator(rule, value){
        return +value === 0 ? Promise.reject() : Promise.resolve()
    }

    function onSearch(value, account){
        new AccountService().search({value}).then(res => {
            if(account==='from'){
                setFromAccounts(res.data.data);
            }else{
                setToAccounts(res.data.data)
            }
        })
    }

    function onSendData(data) {
        onFinish({
            'paytype': data.paytype,
            'from_number': data.from_number || from_number,
            'to_number': data.to_number || to_number,
            'amount': info.amount,
            'procent': info.tax,
            'tax': info.taxCalculated,
            'total': info.total,
            'comment': data.comment
        })
    }

    return <Form {...props} layout='vertical' form={form} onFinish={onSendData}>
        <Form.Item name='paytype' label='тип перевода' rules={[
            {required: true, message: 'поле обязательно к заполнению'}
        ]}>
            <Select>
                {payType.map(type => {
                    return <Select.Option value={type.id} key={type.id}>{type.name}</Select.Option>
                })}
            </Select>
        </Form.Item>

        {!from_number ?
            <Form.Item name='from_number' label='счет отправителя' rules={[
                {required: true, message: 'необходимо выбрать счёт отправителя'}
            ]}>
                <Select
                    showSearch
                    onSearch={(v) => onSearch(v, 'from')}
                    notFoundContent={null}
                    filterOption={false}
                    // value={number}
                    // editable={typeof nubmer==='undefined'}
                    placeholder='введите счёт или имя контрагента'
                >
                    {fromAccounts.map(acc => {
                        return <Select.Option key={acc.number} value={acc.number} currency={acc.currency}>
                            <Space size={1} direction='vertical'>
                                <Typography.Text strong>Счёт: {acc.number} Валюта: {acc.currency}</Typography.Text>
                                <Typography.Text>Контрагент: {acc.contragent__name}</Typography.Text>
                            </Space>
                        </Select.Option>
                    })}
                </Select>
            </Form.Item> : <Descriptions bordered size='small' style={{marginBottom: '1em'}}>
                <Descriptions.Item label='Счёт отправителя'>{from_number}</Descriptions.Item>
            </Descriptions>
        }


        <Form.Item required name='to_number' label='счет получателя'>
            <Select
                showSearch
                onSearch={(v) => onSearch(v, 'after')}
                notFoundContent={null}
                filterOption={false}
                onChange={() => setStageVisible(true)}
                placeholder='введите счёт или имя контрагента'
            >
                {toAccounts.map(acc => {
                    return <Select.Option value={acc.number} key={acc.number} currency={acc.currency}>
                        <Space size={1} direction='vertical'>
                            <Typography.Text strong>Счёт: {acc.number} Валюта: {acc.currency}</Typography.Text>
                            <Typography.Text>Контрагент: {acc.contragent__name}</Typography.Text>
                        </Space>
                    </Select.Option>
                })}
            </Select>
        </Form.Item>

        {stageVisible && <>
            <Row gutter={16}>
                <Col xs={12}>
                    <Form.Item name='amount' label='сумма' required rules={[
                        {required: true, message: 'должна быть указана сумма'},
                        {validator: negativeAmountValidator, message: 'сумма не может быть отрицательной'},
                        {validator: zeroAmountValidator, message: 'сумма не может быть равной нулю'}
                    ]}>
                        <DebouncedInputNumber
                            onChange={computeInfoChange}
                            value={(taxIsUp)?info?.amount:info?.total}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item name='procent'
                        label={<><Space>
                                <Checkbox onChange={val => {
                                    toggleTaxDisabled();
                                }}/>
                                НДС
                               <Checkbox onChange={e=>{
                                   taxIsUpToggle(!taxIsUp);
                               }}
                               checked={taxIsUp}/>
                                   Сверху
                               </Space></>
                        }
                    >
                        <InputNumber
                            disabled={taxDisabled}
                            onChange={computeInfoChange}
                            value={20}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name='comment' label='комментарий'>
                <Input.TextArea/>
            </Form.Item>

            <Descriptions title='Предварительный расчёт' size='small' bordered column={2}>
                <Descriptions.Item label='сумма' span={2}>{info.amount}</Descriptions.Item>
                <Descriptions.Item label='НДС'>{info.tax}%</Descriptions.Item>
                <Descriptions.Item label='сумма'>{info.taxCalculated}</Descriptions.Item>
                <Descriptions.Item label='Итого'>{info.total}</Descriptions.Item>
            </Descriptions>

        </>}

    </Form>
}

TransferForm.propTypes = {
    number: PropTypes.string,
    onFinish: PropTypes.func.isRequired
}
