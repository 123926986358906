import React, {useEffect} from "react";
import { Modal, Form, Button } from "antd";
import PackageForm from '../Forms/PackageForm';


export function PackageModal({item, ...props}){

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        item && form.setFieldsValue(item);
    }, [item]);

    return (
        <Modal 
            id="create_package"
            title={item ? 'Изменить пакетное прделожение' : 'Создать пакетное предложение'}
            destroyOnClose
            width={'80%'}
            footer={
                <Button type="primary" htmlType="submit" form="create_package">
                    {item ? 'Изменить' : 'Сохранить'}
                </Button>
            }
            {...props}
        >
            <PackageForm id="create_package" form={form} onCancel={props.onCancel} item={item ? item : false}/>
        </Modal>
    )
}
