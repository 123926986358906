import React, {useContext, useMemo, useState} from "react";
import {useToggle} from "react-use";
// import { AccountService } from "../API/AccountAPI";


const AccountContext = React.createContext();


// class Account{

//   constructor({number}){
//     this.number = number
//     this.query = new AccountService(this.number)
//   }
// }

export function AccountProvider({children}) {
    // Контекст хранилища по работе с авторизацией и пользователем

    const [account, setAccount] = useState(null);
    const [update, toggle] = useToggle(false);

    // const [currency, setCurrency] = useState(null); не нужно, весь объект теперь хранится в account

    const memoedValue = useMemo(
        () => ({
            account,
            update,
            toggle,
            setAccount,
        }),
        [account, update]
    );

    return (
        <AccountContext.Provider value={memoedValue}>
            {children}
        </AccountContext.Provider>
    );
}

export default function useAccount() {
    return useContext(AccountContext);
}
