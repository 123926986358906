import {useForm} from 'antd/es/form/Form'
import React from 'react'

import ContractForm, {ContractAtolForm, ContractAtolEditForm} from '../Forms/ContractForm'
import Modal from '../../../Base/Modals/Modal'
import useIntegration from "../../../Providers/IntegrationsProvider";

export default function ContractModal({   visible,
                                          onCancel,
                                          onSuccess,
                                          seller,
                                          type,
                                          ...props}) {
    const [form] = useForm();
    const {atol} = useIntegration();

    function _onSuccess() {
        form.submit();
        // onSuccess && onSuccess();
    }

    function _onFinish() {
        onSuccess && onSuccess();
    }

    return (
        <Modal visible={visible}
               btnname='OK'
               onSuccess={_onSuccess}
               onCancel={onCancel}
               width='70%'
               title='Добавить договор'
               {...props}>
            {atol ?
                <ContractAtolForm form={form} seller={seller}
                                  onFinish={_onFinish}
                                  type={type}/> :
                <ContractForm form={form} seller={seller}
                              type={type}
                              onFinish={_onFinish} />
            }
        </Modal>
    )
}

export function ContractUpdateModal(props) {
    const [form] = useForm();
    const {atol} = useIntegration();
    
    function onFinish(data) {
        typeof props.onFinish === 'function' && props.onFinish(data);
    }

    return (
        <Modal
            btnname='OK'
            width='70%'
            onSuccess={() => form.submit()}
            title='Редактировать договор'
            {...props}
        >
            {atol ? 
                <ContractAtolEditForm form={form} setIsModal={props.setIsModal} {...props}/>: 
                <ContractForm form={form} item={props.row} onFinish={onFinish} />
            }
        </Modal>
    )
}
