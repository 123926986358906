import React from "react";
import {Tabs} from "antd";

import UserTable from "./Tables/UserTable";
import {Card} from '../../Base';


export default function Usermng({...props}) {

  return <Card>
          <Tabs>
            <Tabs.TabPane tab="Пользователи" key="1">
              <UserTable />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Роли" key="2" />
            <Tabs.TabPane tab="Привилегии" key="3" />
          </Tabs>
        </Card>
}
