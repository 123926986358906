import React from "react";
import {Button, Modal} from "antd";
import PayUpForm from "../Forms/PayUpForm";


export default function PayUpModal({onSuccess, ...props}) {

    return (
        <Modal title="Пополнить счёт" destroyOnClose {...props}
               footer={
                   <Button type="primary" htmlType="submit" form="payup_form">
                       Пополнить
                   </Button>
               }>
            <PayUpForm id="payup_form" onFinish={onSuccess}/>
        </Modal>
    );
}
