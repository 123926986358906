import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';

import {Button, Col, Dropdown, Menu, message, Card, Popconfirm, Row, Space, Table, Typography} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ShoppingCartOutlined
} from "@ant-design/icons";
import useUser from "../../Providers/UserProvider";

import {PackageService} from "app/API/PackageAPI";
import {ContragentService} from "app/API/ContragentAPI";
import {CartService} from "app/API/OrderAPI";

import {productStatusTag} from "../Common/Tags";
import {PackageOrderModal} from "./Modals/OrderModal";

import usePackage, {PackageProvider} from "./PackageContext";

import Money from "../../Components/Accounts/utils/Money";
import {PackageModal} from "./Modals/PackageModal";
import useContragent from "../Contragents/ContragentContext";


export function PackageTable({onPackageClick}) {

    const [modalPackageVisible, setModalPackageVisible] = useState({visible: false});
    const [packages, setPackages] = useState([]);
    const [update, setUpdate] = useState(false);
    const {contragent, refreshCart} = useContragent();

    useEffect(() => {
        // new PackageService().list().then(res => {
        //     setPackages(res.data.data.map(el => {
        //         el.key = el.id;
        //         return el
        //     }))
        // })

        new ContragentService(contragent?.id).getPackages().then(res => {
            setPackages(res.data.data.map(el => {
                el.key = el.id;
                return el
            }))
        })
    }, [modalPackageVisible, update]);

    function addToBucket(record) {
        new CartService(contragent?.id).add_package({
            contragent: contragent.id,
            package: record?.id
        })
        refreshCart();
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },

        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: function (name, item) {
                if (typeof onPackageClick === 'function')
                    onPackageClick(item);
                return <Link to={`/packages/${item.id}`}>{name}</Link>
            }
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: productStatusTag,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: 'right',
            render: (value, record) => (
                <Space disabled={false}>
                    <Button
                        size='small' icon={<PlusOutlined/>}
                        onClick={() => {
                            setModalPackageVisible({visible: true, item: record});
                        }}
                    />
                    <Button
                        size='small'
                        icon={<ShoppingCartOutlined/>}
                        onClick={() => addToBucket(record)}
                    />
                </Space>
            )
        },
    ];

    return (
            <Table
                size='small'
                columns={columns}
                dataSource={packages}
                width={1000}
                expandable={{
                    expandedRowRender: (record) => (
                        <PackageProvider initial_package={record}>
                            <PackageItemTable />
                        </PackageProvider>
                    ),
                }}
                title={() => (
                    <Row justify='end'>
                        <Col>
                            {/* <OfferModal
                                visible={modalVisible}
                                onCancel={() => {setModalVisible(false); setUpdate(p => !p)}}
                            /> */}
                            <PackageOrderModal
                                // visible={orderPackageModalVisible && !['reconciliation', 'derivation'].includes(orderModalType) }
                                visible={modalPackageVisible?.visible}
                                item={modalPackageVisible?.item}
                                onCancel={() => {setModalPackageVisible({visible: false}); setUpdate(p => !p)}}
                            />
                        </Col>
                    </Row>
                )}
                pagination={{pageSize: 100}}
            />
    )

}

export default function CardPackageTable({onPackageClick}) {

    const [modalPackageVisible, setModalPackageVisible] = useState({visible: false});
    const [packages, setPackages] = useState([]);
    const [update, setUpdate] = useState(false);
    const {is_global_permitted} = useUser();

    useEffect(() => {
        if (!modalPackageVisible?.visible) {
            new PackageService().list().then(res => {
                setPackages(res.data.data.map(el => {
                    el.key = el.id;
                    return el
                }))
            })
        }
    }, [modalPackageVisible, update]);

    function access_actions(){
        let access = [];

        function render_menu(record){

            function delete_package(){
                new PackageService(record?.id).delete().then(res => {
                    message.success(res?.data.msg);
                  setUpdate(p => !p);
                })
            }
            // console.log(contragent, record)

            return (
                <Menu>
                    {is_global_permitted('application.package.edit') &&
                        <Menu.Item icon={<EditOutlined />} 
                                   onClick={() => setModalPackageVisible({visible: true, item: record})}
                        >Редактировать</Menu.Item>
                    }
                    {is_global_permitted('application.package.delete') &&
                        <Popconfirm
                            title={`вы уверены, что хотите удалить ${record?.name}`}
                            onConfirm={delete_package}
                            okText="Удалить"
                            cancelText="Отменить"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                            <Menu.Item icon={<DeleteOutlined />} danger>Удалить</Menu.Item>
                        </Popconfirm>
                    }
                </Menu>
            )
        }

        if(is_global_permitted('application.package.delete')){
            access.push({
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 50,
                render: (value, record) => (
                    <Dropdown overlay={() =>  render_menu(record)} trigger={['click']}>
                        <Button size="small" icon={<MoreOutlined/>}/>
                    </Dropdown>
                )
            })
        }
        return access
    }

    const columns = [
        ...access_actions(),
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: function (name, item) {
                if (typeof onPackageClick === 'function')
                    onPackageClick(item);
                return <Link to={`/packages/${item.id}`}>{name}</Link>
            }
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: productStatusTag,
        }
    ];

    return (
        <Card
            title='Пакетные предложения'
            extra={
            // <Permission global={"create_package"}>
                <Button type='primary' onClick={() => setModalPackageVisible({visible: true})}>Создать</Button>
            // </Permission>
        }
        >
            <Table
                size='small'
                columns={columns}
                dataSource={packages}
                expandable={{
                    expandedRowRender: (record) => (
                        <PackageProvider initial_package={record}>
                            <PackagePriceTable />
                        </PackageProvider>
                    ),
                }}

                pagination={{pageSize: 100}}
            />
            <PackageModal visible={modalPackageVisible?.visible} 
                          item={modalPackageVisible?.item} 
                          onCancel={() => setModalPackageVisible({visible: false})}/>
        </Card>)
}


export function PackageItemTable(){ // TODO: Почти полная копия PackagePriceTable  разобраться. Отрефакторить

    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [modalVisible, setModalVisible] = useState(false);
    // const [orderPackageModalVisible, setOrderPackageModalVisible] = useState(false);
    // const [orderModalType, setOrderModalType] = useState(null);
    // const [update, setUpdate] = useState(false);
    const {packages} = usePackage();

    // const {contragent, setBucket} = useUser()

    const {Text} = Typography;

    useEffect(() => {
        if (packages?.id) {
            setLoading(true);
            new PackageService(packages?.id).getPrices().then(res => {
                setPrices(res.data.data);
            }).finally(() => {
                setLoading(false)
            });
        }
    }, [packages]);

    // function addToBucket() {
    //     new CartService(contragent?.id).add_package({contragent: contragent.id, package: packages?.id})
    //     .then(res => setBucket(res.data))
    // }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'описание',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'контрагент',
            dataIndex: 'contragent_name',
            key: 'contragent_name',
        },
        {
            title: 'цена',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => (
                <Money sum={value} currency={record.currency} />
            )
        },
        {
            title: 'количество',
            dataIndex: 'count',
            key: 'count',
            align: 'right',
        },
        {
            title: 'налоговая ставка',
            dataIndex: 'taxes',
            key: 'taxes',
            render: (value) => {
                return value === 'NO_VAT' ? 'Без НДС' : `${value}%`
            }
        },
        {
            title: 'предоплата',
            dataIndex: 'pre',
            key: 'pre',
            render: (value) => {
                return `${value} %`
            }
        },
        {
            title: 'тип услуги',
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: 'сумма',
            dataIndex: 'sum',
            key: 'sum',
            render: (value, record) => {
                let sum = parseFloat(record.price) * parseFloat(record.count);
                return `${sum}`
                // <Money sum={parseFloat(record.price) * parseFloat(record.count)} />
            }
        },
    ];

    return <Table
        size="small"
        columns={columns}
        dataSource={prices}
        pagination={false}
        loading={loading}
        bordered
        summary={(pageData) => {
            let totalSum = 0;
            pageData.forEach(({price, count}) => {
                totalSum += parseFloat(price) * parseFloat(count);
            });
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>Итого:</Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                            <Text type="danger"><Money sum={totalSum} /></Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }}
    />
}

export function PackagePriceTable(){ // TODO: Почти полная копия PackageItemTable разобраться. Отрефакторить

    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const {packages} = usePackage();
    const { Text } = Typography;

    useEffect(() => {
        if (packages?.id) {
            setLoading(true);
            new PackageService(packages?.id).getPrices().then(res => {
                setPrices(res.data.data);
            }).finally(() => {
                setLoading(false)
            });
        }
    }, [packages, update]);

    // function addToBucket() {
    //     new CartService(contragent?.id).add_package({contragent: contragent.id, package: packages?.id})
    //     .then(res => setBucket(res.data))
    // }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'описание',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'контрагент',
            dataIndex: 'contragent_name',
            key: 'contragent_name',
        },
        {
            title: 'цена',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => (
                <Money sum={value} currency={record.currency} />
            )
        },
        {
            title: 'количество',
            dataIndex: 'count',
            key: 'count',
            align: 'right',
        },
        {
            title: 'налоговая ставка',
            dataIndex: 'taxes',
            key: 'taxes',
            render: (value) => {
                return value === 'NO_VAT' ? 'Без НДС' : `${value}%`
            }
        },
        {
            title: 'предоплата',
            dataIndex: 'pre',
            key: 'pre',
            render: (value) => {
                return `${value} %`
            }
        },
        {
            title: 'тип услуги',
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: 'сумма',
            dataIndex: 'sum',
            key: 'sum',
            align: 'right',
            render: (value, record) => {
                let sum = parseFloat(record.price) * parseFloat(record.count);
                return <Money sum={sum} currency={record.currency} />
            }
        },
    ];

    return <Table
        size="small"
        columns={columns}
        dataSource={prices}
        pagination={false}
        loading={loading}
        bordered
        summary={(pageData) => {
            let totalSum = 0;
            pageData.forEach(({ price, count }) => {
                totalSum += parseFloat(price) * parseFloat(count);
            });
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}><Text strong>Итого:</Text></Table.Summary.Cell>
                        <Table.Summary.Cell index={8} align="right" >
                            <Text strong>
                                <Money sum={totalSum} />
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }}
    />
}


export function PackageOrderTable({...props}) {

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => (
                <Money sum={value} currency={record.currency} />
            )
        },
        {
            title: 'Налоговая ставка',
            dataIndex: 'percent_tax',
            key: 'percent_tax',
            render: (value) => {
                return value === 'NO_VAT' ? 'Без НДС' : `${value}%`
            }
        },
        {
            title: 'Тип услуги',
            dataIndex: 'product_type_name',
            key: 'product_type_name',
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            // render: (value, record) => (
            //     <Space size="middle">
            //         <InputNumber
            //             defaultValue={record.count}
            //             min={record.min_orders}
            //             max={record.max_orders}
            //             size='small'
            //             onChange={(val) => handleChange(val, record)}
            //         />
            //     </Space>
            // )
        },
        // {
        //     title: 'макc',
        //     dataIndex: 'max_orders',
        //     key: 'max_orders',
        // },
        {
            title: 'Сумма',
            dataIndex: 'sum',
            key: 'sum',
            align: 'right',
            render: (value, record) => {
                return <Money sum={parseFloat(record.price) * parseFloat(record.count)} />
            }
        },
        {
            title: 'Скидка',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            render: (value, record) => {
                return <Money sum={value} />
            }
        },
    ];

    return <Table
        size="small"
        columns={columns}
        pagination={false}
        bordered
        {...props}
    />
}

