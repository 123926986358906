import {Button, Col, Dropdown, Menu, Row, Typography, Table, Space, message, notification} from 'antd'
import {MoreOutlined, ReloadOutlined} from '@ant-design/icons';
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import DocumentService, { DebtInvoiceService } from '../../../API/DocumentAPI'
import Money from '../../Accounts/utils/Money';
import DocModal from "../Modals/DocModal";
import DocumentCreateModal from "../Modals/DocumentCreateModal";
import RefillInvoiceModal from "../Modals/RefillInvoiceModal";
import { DateTime } from '../../Common/OtherComponents';
import useUser from '../../../Providers/UserProvider';


export default function DocumentTable({doctype, ...props}) {
    const [data, setData] = useState([]);
    const [docVisible, setDocVisible] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [doc, setDoc] = useState({id: null, type: null, href: null});
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const {contragent} = useUser();

    const columns = [
        {title: '№', dataIndex: 'id', width: 50},
        {
            title: 'Наименование',
            dataIndex: 'document_url',
            render: (text, record) => {
                return <Typography.Link onClick={() => new DocumentService().downloadDocument(record.document_url)}>
                    {record.name || record.number}
                </Typography.Link>
            }
        },
        {title: 'Тип', dataIndex: 'type'},
        {
            title: 'Сумма', dataIndex: 'pure_amount', render: sum => (
                <Money sum={parseFloat(sum)}/>
            )
        },
        {
            title: 'Контрагент', dataIndex: 'buyer_name'
        },
        {
            title: 'Сумма с НДС', dataIndex: 'amount', render: sum => (
                <Money sum={parseFloat(sum)}/>
            )
        },
        {title: 'Дата', dataIndex: '_created', render: dt => (
            <DateTime dt={dt} />
            )
        },
        {
            title: '',
            key: 'operation',
            width: 10,
            render: (record) => {
                return <Dropdown overlay={(
                    <Menu>
                        <Menu.Item key='1'>
                            <Typography
                                onClick={() => {
                                    setDoc({id: record.id, type: record.type, href: record.document_url});
                                    setDocVisible(true)
                                }}
                            >Показать документ</Typography>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Typography onClick={() => new DocumentService().downloadDocument(record.document_url)}>
                                Загрузить PDF</Typography>
                        </Menu.Item>
                    </Menu>
                )} trigger={['click']} placement='bottomRight'>
                    <Button type='text' icon={<MoreOutlined/>}/>
                </Dropdown>
            }
        },
    ];
    const params = useParams();

    useEffect(() => {
        setLoading(true);
        new DocumentService().list({params: {contragent: params.id}}).then((r) => {
            setData(r.data.data);
            setLoading(false);
        });
    }, [params.id, update]);

    function handleDebtInvoice() {
        new DebtInvoiceService().create({contragent: contragent.id}).then((r) => {
            notification.success({message: `Счет на задолженность сформирован.`}
            )}
        )
    }

    return (
            <Table
                columns={columns}
                dataSource={data}
                rowKey='id'
                size='small'
                loading={loading}
                title={() => (
                    <Row justify='space-between'>
                        <Col>
                            <Space>
                                <Button onClick={() => setIsModal(true)}>Счёт на пополнение</Button>
                                <Button onClick={handleDebtInvoice}>Счёт на задолженность</Button>
                                {/* TODO: ЗАПИЛИТЬ СОЗДАНИЕ ДОКУМЕНТОВ!!! */}
                                {/* <Button>Создать документ</Button> */}
                            </Space>
                            <RefillInvoiceModal
                                onCancel={() => {
                                    setIsModal(false);
                                    setUpdate(p => !p)
                                }}
                                setIsModal={setIsModal}
                                visible={isModal} />
                        </Col>
                        <Col>
                            <Button icon={<ReloadOutlined/>} onClick={() => setUpdate(p => !p)}/>
                            <DocumentCreateModal type={doctype} visible={doctype != null}/>
                            <DocModal
                                link={doc.href}
                                visible={docVisible}
                                onCancel={() => setDocVisible(prev => !prev)}
                                doc_id={doc.id}
                                title={doc.type}
                                height={'720px'}
                                width={'1150px'}
                            />
                        </Col>
                    </Row>
                )}
                {...props}/>
    )
}
