import {EditOutlined, ReloadOutlined, CheckOutlined} from "@ant-design/icons";
import {Button, Col, Input, InputNumber, Row, Select, Space, Table, Tag} from "antd";
import {useEffect, useRef, useState} from "react";
import {useEffectOnce} from "react-use";
import {ContragentService} from "src/API/ContragentAPI";
import {OrderItemService} from "src/API/OrderAPI";
import {SubscribeService} from "src/API/ServiceAPI";
import Money, {NumberValue} from "../../Accounts/utils/Money";
import {DateTime} from "../../Common/OtherComponents";
import useContragent from "../ContragentContext";
import {notification} from "antd";
import styles from  "./BillingTable.module.less";

function is_editable_status(record) {
    // console.log(record, res);
    return record && (
        record.revenue_status == 'template'
        || record.revenue_status == 'fake'
        || !record.revenue_status);
}

function GreenButton({onClick, ...props}) {
    return <Button icon={<CheckOutlined/>}
                   size={'small'}
                   className={styles.greenbutton}
                   onClick={onClick}
        {...props}
    />;
}

function NumberEditControl({children, value, record, onChange}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [_val, set_val] = useState(value);

    useEffect(() => {
        if (edit && ref.current)
            ref.current.focus();
    }, [edit]);

    function _onChange(val) {
        if (_val != value) {
            if (typeof _val === "number")
                onChange?.(_val);
            else if (typeof _val === "string")
                onChange?.(parseFloat(_val.replace(",",".")));
        }
        setEdit(false);
    }

    return <Space direction={"horizontal"}>
        {(!edit) ? (value && children):
            <InputNumber //onBlur={() => setEdit(false)}
                         size={'small'}
                         className={styles.greenbuttoninput}
                         value={_val}
                         key={record.id}
                         addonAfter={<GreenButton onClick={_onChange}/>}
                         onPressEnter={_onChange}
                         onChange={set_val}
                         ref={ref}/>
        }
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}

function DescriptionEditControl({children, value, record, onChange}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [local_val, setLocal_val] = useState(value);

    useEffect(() => {
        if (edit && ref.current)
            ref.current.focus();
    }, [edit]);

    function _onChange(val) {
        if (local_val != value)
            onChange?.(local_val);
        setEdit(false);
    }

    return <Space direction={"horizontal"}>
        {(!edit) ? <>
                {children}
            </> :
            <Input onBlur={() => setEdit(false)}
                   onPressEnter={_onChange}
                   value={local_val}
                   size={'small'}
                   onChange={e => setLocal_val(e.target.value)}
                   ref={ref}/>
        }
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}


function RevenueSelectControl({rnum, onChange, record, service_id}) {
    const [edit, setEdit] = useState(false);
    const ref = useRef(null);
    const [revenues, setRevenues] = useState([]);
    const [revMap, setRevMap] = useState({});

    useEffect(() => {
        if (edit) {
            ref?.current?.focus?.();

            if (service_id) {
                new SubscribeService(service_id).getAvailRevenues()
                    .then(r => {
                        const data = ([
                            {id: null, number: "- нет -"},
                            {id: 0, number: "- fake -"}
                        ]).concat(r.data.data);
                        setRevenues(data);
                        setRevMap(Object.fromEntries(data.map(x => [x.id, x])));
                    });
            }
        }
    }, [edit]);

    function _onChange(val) {
        const obj = revMap[val];
        onChange(obj);
        setEdit(false);
    }

    function revenue_number() {
        switch (record.revenue_status) {
            case "template":
                return <Tag>{rnum}</Tag>;
            case "active":
                return <Tag color={"green"}>{rnum}</Tag>;
            case "incorrect":
                return <Tag color={"yellow"}>{rnum}</Tag>;
            case "problem":
                return <Tag color={"red"}>{rnum}</Tag>;
            case 'fake':
                return <Tag color={"grey"}>fake:{rnum}</Tag>;
            default:
                return rnum;
        }
    }

    return <Space direction={"horizontal"}>
        {(!edit) ?
            <>{revenue_number()}</>:
        <Select onSelect={_onChange}
                value={rnum}
                onBlur={x => setEdit(false)}
                size={'small'}
                showSearch
                ref={ref}
        >
            {revenues?.map?.(x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.number}
                </Select.Option>))}
        </Select>}
        {is_editable_status(record) &&
            <EditOutlined onClick={() => setEdit(!edit)} style={{marginLeft: "10px"}}/>}
    </Space>;
}


export default function BillingOrderTable({order_item, ...props}) {

    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        new OrderItemService(order_item).history_list_counter().then(res => {
            setBillingList(res.data.data);
            setGeneralInfo({
                count: res.data?.count || 0,
                amount: res.data?.amount,
                taxes: res.data?.taxes,
                without_taxes: res.data?.without_taxes,
            });
        });

    }, [order_item]);

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "значение",
            dataIndex: "count",
        },
        {
            title: "сумма без НДС", dataIndex: "without_tax", render: (_, record) => (
                <Money sum={record.amount - record.taxes}/>
            ),
        },
        {
            title: "сумма", dataIndex: "amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "НДС", dataIndex: "taxes", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },
        {
            title: "дата внесения", dataIndex: "_created", width: 150, render: (dt) => {
                return <DateTime dt={dt}/>;
            },
        },
        {
            title: "утчен в расчетах?",
            dataIndex: "invoice_doc_id",
            key: "invoice_doc_id",
            render: (value) => {
                return value === null ? "Нет" : "Да";
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey="id"
            size="small"
            summary={(_) => {

                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.count}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.without_taxes}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.amount}</Table.Summary.Cell>
                            <Table.Summary.Cell>{generalinfo?.taxes}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
    );
}

export function SubscribePlanTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});

    useEffectOnce(() => {
        if (service_item && service_item?.id) {
            new SubscribeService(service_item?.id).getPlan().then(res => {
                setBillingList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    total_billing_amount: res.data?.total_billing_amount,
                });
            });
        }

    }, [service_item]);

    function onChangeValue(value, field, plan_id) {
        new SubscribeService(service_item?.id)
            .setPlanFactField(plan_id, {[field]: value})
            .then(r => {
                const newBL = billingList.map(x => {
                    if (x.id == plan_id)
                        x[field] = r.data[field];
                    return x;
                });
                setBillingList(newBL);
            }).catch(e => {
                notification.error({
                    message: "неправильное значение",
                    description: "Неправильное значение"
                })
        });
    }

    function onChangeRevenue(value, plan_id) {
        new SubscribeService(service_item?.id)
            .setPlanFactField(plan_id, {revenue_id: value.id, revenue_number: value.number})
            .then(r => {
                const newBL = billingList.map(x => {
                    if (x.id == plan_id) {
                        x["revenue_number"] = r.data["revenue_number"];
                        x["revenue_status"] = r.data["revenue_status"];
                        x["billing_amount"] = r.data["billing_amount"];
                    }
                    return x;
                })
                setBillingList(newBL);
            })
            .catch(e => {
                notification.error({
                    message: "неправильная реализация",
                    description: "Выбрана неправильная реализация"
                })
            });
    }

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "Дата создания", dataIndex: "created", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "План", dataIndex: "amount", render: (sum, record) => (
                <NumberEditControl value={sum}
                                   record={record}
                                   onChange={value => onChangeValue(value, "amount", record.id)}>
                    <Money sum={sum} precision={4}/>
                </NumberEditControl>
            ),
        },
        {
            title: "Факт", dataIndex: "billing_amount", render: (sum, record) => (
                <NumberEditControl value={sum}
                                   record={record}
                                   onChange={value => onChangeValue(value, "billing_amount", record.id)}>
                    <Money sum={sum} precision={4}/>
                </NumberEditControl>
            ),
        },
        {
            title: "Значение", dataIndex: "count", width: 100, render: (cnt, rec) => (
                <NumberEditControl value={cnt}
                                   record={rec}
                                   onChange={value => onChangeValue(value, "count", rec.id)}>
                    <NumberValue value={cnt} precision={1}/>
                </NumberEditControl>
            ),
        },
        // {
        //     title: "№ реализации", dataIndex: "revenue_number", render: (rnum, record) => {
        //         switch (record.revenue_status) {
        //             case "template":
        //                 return <Tag>{rnum}</Tag>;
        //             case "active":
        //                 return <Tag color={"green"}>{rnum}</Tag>;
        //             case "incorrect":
        //                 return <Tag color={"yellow"}>{rnum}</Tag>;
        //             case "problem":
        //                 return <Tag color={"red"}>{rnum}</Tag>;
        //             default:
        //                 return rnum;
        //         }
        //     },
        // },
        {
            title: "№ реализации", dataIndex: "revenue_number", render: (rnum, record) => (
                <RevenueSelectControl rnum={rnum} record={record}
                                      onChange={value => onChangeRevenue(value, record.id)}
                                      service_id={service_item?.id}
                />
            ),
        },
        {
            title: "Фактическая дата", dataIndex: "date", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
            render: (val, rec) => (
                <DescriptionEditControl value={val}
                                        record={rec}
                                        onChange={value => onChangeValue(value, "description", rec.id)}>
                    {val}
                </DescriptionEditControl>
            ),
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey="id"
            size="small"
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell>-</Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_billing_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
    );
}

export function SubscribeDetalizationTable({service_item, ...props}) {
    const [billingList, setBillingList] = useState([]);
    const [generalinfo, setGeneralInfo] = useState({});


    useEffectOnce(() => {
        if (service_item && service_item?.id) {
            new SubscribeService(service_item?.id).getDetalization().then(res => {
                setBillingList(res.data.data);
                setGeneralInfo({
                    total_amount: res.data?.total_amount,
                    // total_tax: res.data?.total_tax,
                });
            });
        }

    }, [service_item]);

    const columns = [
        {title: "№", dataIndex: "id", width: 50},
        {
            title: "сумма", dataIndex: "amount", render: sum => (
                <Money sum={parseFloat(sum)}/>
            ),
        },

        {title: "Значение", dataIndex: "count", width: 100},
        {
            title: "Дата создания", dataIndex: "created", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "Фактическая дата", dataIndex: "date", width: 100, render: (dt) => {
                return <DateTime dt={dt} dateOnly/>;
            },
        },
        {
            title: "комментарий",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={billingList}
            rowKey="id"
            size="small"
            summary={(_) => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Итого</Table.Summary.Cell>
                            <Table.Summary.Cell><Money
                                sum={parseFloat(generalinfo?.total_amount)}/></Table.Summary.Cell>
                            {/*<Table.Summary.Cell><Money sum={parseFloat(generalinfo?.total_tax)}/></Table.Summary.Cell>*/}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
            {...props}
        />
    );
}

// export function BillingServiceTable({service_item, ...props}) {
//     const [billingList, setBillingList] = useState([]);
//     const [generalinfo, setGeneralInfo] = useState({});


//     useEffectOnce(() => {
//         if (service_item && service_item?.id) {
//             new SubscribeService(service_item?.id).getBillingList().then(res => {
//                 setBillingList(res.data.data);
//                 setGeneralInfo({
//                     count: res.data?.count || 0,
//                     amount: res.data?.amount,
//                     taxes: res.data?.taxes,
//                     without_taxes: res.data?.without_taxes
//                 })
//             })
//         }

//     }, [service_item])

//     const columns = [
//         {title: '№', dataIndex: 'id', width: 50},
//         {
//             title: 'значение',
//             dataIndex: 'count'
//         },
//         {
//             title: 'сумма без НДС', dataIndex: 'without_tax', render: (_, record) => (
//                 <Money sum={record.amount - record.taxes}/>
//             )
//         },
//         {
//             title: 'сумма', dataIndex: 'amount', render: sum => (
//                 <Money sum={parseFloat(sum)}/>
//             )
//         },
//         {
//             title: 'НДС', dataIndex: 'taxes', render: sum => (
//                 <Money sum={parseFloat(sum)}/>
//             )
//         },
//         {
//             title: 'комментарий',
//             dataIndex: 'description',
//             key: 'description',
//         },
//     ]

//     return (
//         <Table
//             bordered
//             columns={columns}
//             dataSource={billingList}
//             rowKey='id'
//             size='small'
//             summary={(_) => {
//                 return (
//                     <Table.Summary fixed>
//                         <Table.Summary.Row>
//                             <Table.Summary.Cell>Итого</Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.count)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.without_taxes)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.amount)}/></Table.Summary.Cell>
//                             <Table.Summary.Cell><Money sum={parseFloat(generalinfo?.taxes)}/></Table.Summary.Cell>
//                         </Table.Summary.Row>
//                     </Table.Summary>
//                 )
//             }}
//             {...props}
//         />
//     )
// }

export function BillingCloseDayTable() {
    const {contragent} = useContragent();
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);


    const columns = [
        {title: "№", dataIndex: "id", key: "id"},
        {
            title: "дата биллинга", dataIndex: "date_billing", render: (dt) => {
                return <DateTime dt={dt}/>;
            },
        },
        {title: "описание", dataIndex: "description"},
        {
            title: "статус", dataIndex: "status", render: (_, record) => {

                switch (record.status) {
                    case 1:
                        return "в процессе";
                    case 2:
                        return "успешно";
                    case 3:
                        return "провален";
                }
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        new ContragentService(contragent?.id).listCloseDayBilling().then(res => {
            setList(res.data.data);
        }).finally(_ => {
            setLoading(false);
        });

    }, [contragent, update]);

    return (
        <Table
            title={() => (
                <Row justify="space-between">
                    <Col>

                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setUpdate(p => !p);
                            setLoading(true);
                        }}/>
                    </Col>
                </Row>
            )}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={list}
        />
    );
}
