import React from "react";
import {Layout, Space, Typography, Row, Col, Divider} from "antd";
import useSystemInfo from "../../Providers/SystemProvider";


export default function Footer(){

    const {system} = useSystemInfo();

    return (
        <Layout.Footer style={{background: 'transparent', padding: '10px 25px'}}>
            <Row justify='center' gutter={16}>
                <Col>
                    <Space>
                        <Typography.Link type='secondary' underline style={{fontSize: '10px'}}>Политика обработки персональных данных</Typography.Link>
                        <Typography.Link type='secondary' underline style={{fontSize: '10px'}}>Согласие на обработку персональных данных</Typography.Link>
                    </Space>
                </Col>
                {process.env.REACT_APP_GIT_SHA && system?.version &&
                    <Col>
                        <Divider type="vertical"/>
                        <Typography.Text type="secondary" style={{fontSize: "10px"}}>
                            frontend: v.{process.env.REACT_APP_GIT_SHA}</Typography.Text>
                        <Divider type="vertical"/>
                        <Typography.Text type="secondary" style={{fontSize: "10px"}}>
                            backend: v.{system?.version || "unknown"}
                        </Typography.Text>
                        <Divider type="vertical"/>
                    </Col>}
                <Col>
                    <Typography.Text type='secondary' style={{fontSize: '10px'}}>PricePlan © 2023</Typography.Text>
                </Col>
            </Row>
        </Layout.Footer>
    )
}
