import React from 'react'
import {Modal} from 'antd'
import BillingOrderTable, {SubscribeDetalizationTable, SubscribePlanTable} from '../Tables/BillingTable';


export default function BillingModal({order_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'1100px'} title='детализация' footer={false}>
            <BillingOrderTable
                order_item={order_item}
            />
        </Modal>
    )
}

export function SubscribePlanModal({service_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'80%'} title='План-Факт' footer={false}>
            <SubscribePlanTable service_item={service_item}/>
        </Modal>
    )
}

export function SubscribeDetalizationModal({service_item, ...props}) {

    return (
        <Modal {...props} destroyOnClose width={'80%'} title='Детализация подписки' footer={false}>
            <SubscribeDetalizationTable service_item={service_item}/>
        </Modal>
    )
}

// export function BillingServiceModal({service_item, ...props}) {

//   return (
//     <Modal {...props} destroyOnClose width={'1100px'} title='детализация' footer={false}>
//       <BillingServiceTable
//         service_item={service_item}
//       />
//     </Modal>
//   )
// }
