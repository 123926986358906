import BaseService from './BaseAPI'
import {AccountService} from "./AccountAPI";


class TransactionService extends BaseService {
    MODEL = 'transaction'

    payUp(data) {
        return this._post('payup', data).catch(TransactionService.error_handler)
    }

    debit(data) {
        return this._post("debit", data).catch(TransactionService.error_handler)
    }

    getByAccount(account, search) {
        return new AccountService(account).getTransactions({search});
    }

    //
    // list({contragent, account}) {
    //     const params = {};
    //     if ((typeof contragent !== 'undefined') && (contragent != null))
    //         params['contragent'] = contragent
    //     if ((typeof account !== 'undefined') && (account != null))
    //         params['account'] = account
    //     return this._get('', { params })
    // }

    payTypes() {
        return this._get('paytype');
    }
}

export {
    TransactionService
}
