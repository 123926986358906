import AuthorizationService from "src/config/autorization_backends";
import axios from "../config/axios";
import {message, notification} from "antd";
import {convert_url} from "app/lib/tools";


export function BaseServiceException(message) {
    this.message = message;
    this.name = "BaseServiceException";
}

export function IdIsNotANumber(message) {
    this.message = message;
    this.name = "IdIsNotANumber";
}


export function error_handler(err, default_message) {
    if (err?.response?.data?.msg) {
        message.error(err.response.data.msg);
    } else if (typeof err?.response?.data === "object") {
        notification.error({
            message: `Код ошибки: ${err.response?.status}`,
            description: JSON.stringify(err.response.data, null, 2),
        });
    } else if (default_message) {
        message.error(default_message);
    }
}


function downloadDocument(url) {

    url = convert_url(url);

    return axios.get(url, {responseType: "blob"}).then(res => {
        const document_url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = document_url;
        link.setAttribute("download", url.split("/").at(-1));
        document.body.appendChild(link);
        link.click();
        return Promise.resolve(res);
    }).catch(err => {
        message.error("Ошибка загрузки файла");
        return Promise.reject(err);
    });
}

class BaseService {
    static _self;
    static API = "/api";
    API = "/api";
    _id;
    get = this.retrieve;

    constructor(id = null) {
        // if (isNaN(id))
        //     throw new IdIsNotANumber("Id should be a number!");
        this._id = id;
    }

    static get MODEL() {
        return new this().model;
    }

    get model() {
        if (this.hasOwnProperty("MODEL"))
            return this.MODEL;
        else
            throw new BaseServiceException("Не определена модель MODEL");
    }

    static error_handler(err, message = "Ошибка", message_type = "error") {
        const mtd = notification[message_type];
        const response = err.response || err; //if err has response or err is response itself

        mtd({
            message: message,
            description: response?.data?.msg
                || response?.data?.message
                || response?.data?.detail
                || response?.data?.details,
        });
        return Promise.reject(err);
    }

    static create_error_handler(message, message_type = "error") {
        return (err) => {
            return BaseService.error_handler(err, message, message_type);
        };
    }

    _check_id() {
        if (!this._id)
            throw new BaseServiceException("Не определён ID сущности");
        else
            return this._id;
    }

    _process_args(args) {
        const types = ["string", "number"];
        let url = Object.values(args)
                        .filter(val => types.includes(typeof val))
                        .map(v => v.toString()).join("/");
        let data = Object.values(args)
                         .filter(val => typeof val === "object");

        return [url, ...data];
    }

    // HTTP методы. базовые. для внутренней работы.
    #request(method, request_args) {
        const [urlpart, data, config] = this._process_args(request_args);
        console.log(request_args, config);
        return axios[method](this._mk_res(urlpart), data, config);
    }

    _get() {
        return this.#request("get", arguments);
    }

    /**
     * url_part string reqired
     * data Object
     * @returns {*}
     * @protected
     */
    _post() {
        return this.#request("post", arguments);
    }

    // ===============================================

    _patch() {
        return this.#request("patch", arguments);
    }

    _put() {
        return this.#request("put", arguments);
    }

    _delete() {
        return this.#request("delete", arguments);
    }

    _options() {
        return this.#request("options", arguments);
    }

    set_id(id) {
        if (!this._id)
            this._id = id;
        else
            throw new BaseServiceException("ID уже привязан к объекту");
    }

    get_id(url) {
        return this._id || "";
    }

    _get_id_if_not_equal(values) {
        return (this.get_id()) ? [this.get_id()] : [];
    }

    _filter_values(values) {
        const [id, ...others] = values;
        return (this.get_id() && this.get_id() === id) ? others : values;
    }

    _mk_res() {
        let values = Object.values(arguments);
        let id = this._get_id_if_not_equal(values);
        let part = this._filter_values(values).join("/");

        let lst = [this.API, this.model].concat(id);
        if (part) lst.push(part);
        return lst.join("/");
    }

    list(params) {
        const path = params?.path || "";
        const pars = params?.params || {};
        // возвращает список
        return this._get(path, {params: pars}).then(res => {
            return Promise.resolve(res);
        }).catch(BaseService.error_handler);
    }

    restore() {
        return this._post("restore").then(res => {
            return res;
        }).catch(BaseService.error_handler);
    }

    retrieve() {
        return this._get().then(res => {
            return res;
        }).catch(BaseService.error_handler);
    }

    create(data) {
        return this._post(data).then(res => {
            return res;
        }).catch(BaseService.error_handler);
    }

    update(data) {
        return this._patch(data).catch(BaseService.error_handler);
    }

    replace(data) {
        return this._put(data).catch(BaseService.error_handler);
    }

    delete() {
        return this._delete()
                   .catch(BaseService.error_handler);
    }

    update_or_create(data) {
        return (this._id) ? this.update(data) : this.create(data);
    }

    options() {
        return this._options()
                   .catch(BaseService.create_error_handler("Не удалось запросить метаданные"));
    }

    search(search_str) {
        return this.list({params: {search: search_str}});
    }

    filter(columns, attributes, filters, page, limit, search) {
        delete filters[undefined];
        return this._post("list-filter", {
            columns: columns || [],
            attributes: attributes || [],
            filters: filters || {},
            page,
            limit,
            search,
        }).catch(BaseService.error_handler);
    }

    csv(columns, attributes, filters, filename = "report.csv") {
        function save_callback(res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();

            return Promise.resolve(res);
        }

        if (columns?.length || attributes?.length) {
            return this._post("csv",
                {
                    columns: columns || [],
                    attributes: attributes || [],
                    filters: filters || {},
                }).then(save_callback);
        } else {
            return this._get("csv", {params: filters}).then(save_callback);
        }
    }

    excel(columns, attributes, filters, filename = "report.xlsx") {

        function saveBlob(blob, fileName) {
            var a = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.dispatchEvent(new MouseEvent("click"));
            window.URL.revokeObjectURL(url);
        }

        const params = (new URLSearchParams(filters)).toString();
        let url_post = convert_url(`${this._mk_res()}/excel`);
        let url_get = convert_url(`${this._mk_res()}/excel?${params}`);
        let backend = AuthorizationService.currentBackend();

        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            if (columns?.length || attributes?.length) {
                xhr.open("POST", url_post, true);
            }
            else {
                xhr.open("GET", url_get, true);
            }
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Authorization", backend.authHeader());
            xhr.responseType = "blob";
            xhr.onload = function (e) {
                if (e.currentTarget.status !== 200) {
                    message.error("Ошибка скачивания документа");
                    reject({status: xhr.status, statusText: xhr.statusText});
                    return;
                }
                var blob = e.currentTarget.response;
                saveBlob(blob, filename);
                resolve()
            };
            if (columns?.length || attributes?.length)
                xhr.send(JSON.stringify({
                    columns: columns || [],
                    attributes: attributes || [],
                    filters: filters || {},
                }));
            else
                xhr.send();
        });



        // TODO: разобраться почему axios не хавает blob в POST. Из-за этого создаются битые документы

        // function fn_callback(res) {
        //     const content_disp = res.headers['content-disposition'];
        //     const contentType = res.headers['content-type'];
        //     const blob = new Blob([res.data], {contentType});
        //     const url = window.URL.createObjectURL(blob);
        //     const link = document.createElement("a");
        //
        //     link.href = url;
        //     link.setAttribute("download", filename);
        //     document.body.appendChild(link);
        //     link.click();
        //
        //     window.URL.revokeObjectURL(blob);
        //     return res;
        // }
        //
        // const config = {
        //     responseType: "blob",
        //     headers: {
        //         Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        //         "Content-Type": "application/vnd.ms-excel",
        //     }};
        //
        // if (columns?.length || attributes?.length) {
        //     return this._post('excel',
        //         {
        //             columns: columns || [],
        //             attributes: attributes || [],
        //             filters: filters || {},
        //
        //         },
        //         config
        //     ).then(fn_callback);
        // } else {
        //     return this._get("excel", {params: filters}, config).then(fn_callback);
        // }
    }

}

export default BaseService;
export {
    BaseService,
    downloadDocument,
};
