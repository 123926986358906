import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Card, Form, Input, InputNumber, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useEffectOnce} from "react-use";
import {AttributeService} from "src/API/DictionaryAPI";
import {AccountDataSelect} from "src/Base/Inputs/AccountSelect";
import ContractSelect from "app/Base/Inputs/ContractSelect";
import useContragent from "../../Contragents/ContragentContext";


const SERVICE_REGULAR = 1;
const SERVICE_COUNTER = 2;
const SERVICE_SUBSCRIBE = 3;


export default function OrderForm({form, type, onChange, ...props}) {

    const {contragent} = useContragent();
    const [attributeModal, setAttributeModal] = useState({visible: false});
    const [attributes, setAttributes] = useState([]);

    useEffectOnce(() => {
        form.resetFields();
        form.setFieldsValue({
            count: 1,
        });
    });

    function onAddAttribute(v) {
        attributes.push(v);
        setAttributeModal({visible: false});
    }

    function onRemoveAttribute(v) {
        let new_attributes = attributes.filter(el => el.codename !== v.codename);
        setAttributes(new_attributes);
    }

    return (
        <Form {...props}
              form={form}
              onChange={onChange}
        >
            <Card size="small" title="данные заказа">
                <Form.Item name="contract" label="Контракт" initialValue={null} rules={[{required: true}]}>
                    <ContractSelect contragent={contragent}
                                    type={3}
                                    showNumber showSellerName/>
                </Form.Item>
                <Form.Item name="account" label="Счёт списания" rules={[{required: true}]}>
                    <AccountDataSelect contragent={contragent}/>
                </Form.Item>
                <Form.Item label="Количество" name="count"
                    // help={`Минимальное возможное количество: ${''}.
                    // Максимально возможное количество: ${'' ? '' : 'неограниченно'}`}
                >
                    <InputNumber defaultValue={1} initialValue={1} min={1} max={null}/>
                </Form.Item>
                {/* <Form.Item label='Реферальный код' name={['attributes', 'ref_code']}>
                    <Input />
                </Form.Item> */}
                {/* <Form.Item name='promocode' validateTrigger={[]} label='Промокод' rules={[{
                    required: false, validator: (_, v) => {
                        if(!v){
                            return Promise.resolve()
                        }
                        return new OrderService().setPromocode({
                            promocode: v
                        }).then(response => {
                            return Promise.resolve()
                        }).catch((err) => {
                            return Promise.reject(err.response?.data?.msg)
                        })
                    }
                }]}>
                        <Input allowClear suffix={<Button htmlType='submit' type='primary' size='small' form='check_promocode'>Применить</Button>} />
                </Form.Item> */}
                <Form.Item>
                    <Card size="small" title="Атрибуты">
                        {attributes.map(el => (
                            <Form.Item label={el.name} name={["attributes", el.codename]}>
                                <Input addonAfter={<MinusCircleOutlined onClick={() => onRemoveAttribute(el)}/>}/>
                            </Form.Item>
                        ))}
                        <Button type="dashed" block
                                icon={<PlusOutlined/>}
                                onClick={() => setAttributeModal({visible: true})}>
                            Добавить атрибут
                        </Button>
                    </Card>
                </Form.Item>
                {type === SERVICE_COUNTER &&
                    <Card size="small" title="данные счетчика">
                        <Form.Item label="начальное значение" name="shift">
                            <Input placeholder="по умолчанию - 0" defaultValue={0}/>
                        </Form.Item>
                    </Card>
                }
                <AttributeModal
                    visible={attributeModal?.visible}
                    onCancel={() => setAttributeModal({visible: false})}
                    onSuccess={onAddAttribute}
                />
            </Card>
        </Form>
    );
}


function AttributeModal({onSuccess, ...props}) {

    const [attributes, setAttributes] = useState([]);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        new AttributeService().list().then(res => {
            setAttributes(res.data.data);
        });
    }, []);

    function onSuccessSelect() {
        target && typeof onSuccess === "function" && onSuccess(target);
    }

    return (
        <Modal size="small" title="Выбрать атрибут" {...props} footer={
            <Button onClick={onSuccessSelect}>Добавить</Button>
        }>
            <Form layout="vertical">
                <Form.Item>
                    <Select onSelect={(_, v) => setTarget({codename: v.value, name: v.name})}>
                        {attributes.map(el => (
                            <Select.Option value={el.codename} name={el.name}>
                                {el.name}
                            </Select.Option>

                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export function PackageOrderForm({onChange, ...props}) {

    const {contragent} = useContragent();

    useEffectOnce(() => {
        props.form.resetFields();
        props.form.setFieldsValue({"type": "new"});
    });

    return (
        <Form {...props} onChange={onChange}>
            <Form.Item name="contract"
                       label="Контракт"
                       initialValue={null}
                       rules={[{required: true, message: "поле обязательно к заполнению"}]}>
                <ContractSelect contragent={contragent?.id}
                                type={3}
                />
            </Form.Item>
            <Form.Item name="account" label="Счёт списания"
                       rules={[{required: true, message: "поле обязательно к заполнению"}]}>
                <AccountDataSelect contragent={contragent?.id}/>
            </Form.Item>
            <Form.Item name="count" label="Количество" initialValue={1}>
                <InputNumber min={1}/>
            </Form.Item>
        </Form>
    );
}
